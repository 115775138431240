/* eslint-disable react-hooks/exhaustive-deps */
import { Button, ButtonGroup, Checkbox, HorizontalGrid, Icon, Popover, ResourceList, Tag, Text, TextField, Tooltip, VerticalStack } from '@shopify/polaris'
import { QuestionMarkInverseMinor, ChevronDownMinor } from "@shopify/polaris-icons";
import React, { useState } from 'react'
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { deleteupdateOfferData, updateOfferData } from '../../../../../Redux/features/NewFunnel/newfunnelSlice';
import languageList from "../../../../../Json/language_list.json"
import ModalCommon from '../../../../../Common/ModalCommon';
import { useEffect } from 'react';

const UpsellOfferText = ({ item, index, funnelData }) => {
    const dispatch = useDispatch();
    const Selectlang = item?.language?.flatMap(obj => obj?.language);
    const [languageData, setLanguageData] = useState({
        language: "",
        offer_text: "",
        show_offer_description: false,
        offer_description: ""
    })

    // language modal...
    const [openModal, setOpenModal] = useState(false);
    const OpenDeleteModal = useCallback(() => setOpenModal(!openModal), [openModal]);
    const [isDisabledFlag, setIsDisabledFlag] = useState(true);

    const [active, setActive] = useState(false);
    const [language, setLanguage] = useState("");
    const [selectedLanguages, setSelectedLanguages] = useState([]);
    const [languages, setLanguages] = useState(languageList);

    useEffect(() => {
        if (languageData?.offer_text !== '') {
            if (languageData?.show_offer_description !== false) {
                if (languageData?.offer_description !== '') setIsDisabledFlag(false);
                else setIsDisabledFlag(true);
            }
            else setIsDisabledFlag(false);
        }
        else setIsDisabledFlag(true);
    }, [languageData])
    useEffect(() => {
        if (Selectlang) {
            setLanguages((prevLanguages) =>
                Selectlang?.reduce((filteredLanguages, value) => {
                    return filteredLanguages.filter((lang) => lang !== value);
                }, prevLanguages)
            );
        }
    }, []);
    const handleOfferTextChange = useCallback((value, name) => {
        const updatedFields = {
            [name]: value.trimStart(),
        };
        dispatch(updateOfferData({ offer_number: item?.offer_number, offer_name: item?.offer_name, updatedFields }));
    }, [funnelData]);

    const setupHandleChange = useCallback((value, name) => {
        const updatedFields = {
            [name]: value,
        };
        dispatch(updateOfferData({ offer_number: item?.offer_number, offer_name: item?.offer_name, updatedFields }));
    }, [funnelData]);

    const tagClickHandler = (lang) => {
        setLanguage(lang);
        const updateLanguageObj = item?.language?.find(langData => langData.language === lang)
        setLanguageData(updateLanguageObj)
        setOpenModal(!openModal)
    }

    const toggleLanguage = useCallback((language) => {
        setOpenModal(true);
        setLanguage(language);
        setLanguageData({
            ...languageData,
            language: language
        })
    }, []);

    const saveLanguage = () => {
        setSelectedLanguages([...selectedLanguages, language]);
        // const index = languages?.indexOf(language);
        // if (index > -1) {
        //     languages.splice(index, 1);
        // }
        setLanguages(languages.filter((lang) => lang !== language))
        setSelectedLanguages(selectedLanguages.filter((lang) => lang !== language));

        const languageIndex = item?.language?.findIndex((languageObj) => languageObj?.language === language);
        if (languageIndex !== -1 && languageIndex !== undefined) {
            const updatedData = item?.language?.map((itemData) => {
                if (itemData.language === language) {
                    return languageData;
                } else {
                    return itemData
                }
            })
            const updatedFields = {
                "language": [...updatedData],
            };
            dispatch(updateOfferData({ offer_number: item?.offer_number, offer_name: item?.offer_name, updatedFields }));

        } else {
            if (item?.language !== null) {
                const updatedFields = {
                    "language": [...item?.language, languageData],
                };
                dispatch(updateOfferData({ offer_number: item?.offer_number, offer_name: item?.offer_name, updatedFields }));
            } else {
                const updatedFields = {
                    "language": [languageData],
                };
                dispatch(updateOfferData({ offer_number: item?.offer_number, offer_name: item?.offer_name, updatedFields }));
            }

        }
        setOpenModal(false);
    }


    return (
        <>
            <HorizontalGrid gap="4" columns={{ md: 2 }}>
                <VerticalStack>
                    <Text variant="headingMd" as="h5">
                        <div className="d-flex align-center">
                            Offer Text
                            <span className="ps-1">
                                <Tooltip content="Entice your buyer to take this offer. The text would appear at the top of this offer page">
                                    <Icon source={QuestionMarkInverseMinor} color="base" />
                                </Tooltip>
                            </span>
                        </div>
                    </Text>
                    <p>Select a product that will be offered to your buyer.</p>
                </VerticalStack>

                <VerticalStack>
                    <TextField
                        placeholder='Deal unlocked! Get this product for 20% off'
                        type="text"
                        value={item?.offer_text}
                        autoComplete="off"
                        onChange={(value) =>
                            handleOfferTextChange(value, "offer_text")
                        }
                    />

                    <div className="mt-2 diff_locals">
                        <Checkbox
                            label="Show different texts for different locales"
                            checked={item?.show_different_language}
                            onChange={(value) =>
                                setupHandleChange(value, "show_different_language")
                            }
                        />
                        {item?.show_different_language && (
                            <div className="">
                                <div className="my-2 tag_wrapper">
                                    {item?.language?.map((language) => (
                                        <div className='tag_item_wrap'
                                            key={language.language}
                                            onClick={() => { tagClickHandler(language.language) }}
                                        >
                                            <Tag
                                                onRemove={(e) => {
                                                    e.stopPropagation();
                                                    dispatch(deleteupdateOfferData({ offer_number: item?.offer_number, offer_name: item?.offer_name, updatedFields: language.language, name: "language_delete", upsell: 'upsell_delete_language' }))
                                                    setLanguages([...languages, language?.language])
                                                }}
                                            >
                                                {language.language}
                                            </Tag>
                                        </div>
                                    ))}
                                </div>
                                <ButtonGroup segmented>
                                    <Button
                                        primary
                                        onClick={() => setActive(!active)}
                                        accessibilityLabel="Add Language"
                                    >
                                        Add Language
                                    </Button>
                                    <div style={{ width: "3px" }} />
                                    <div className="">
                                        <Popover
                                            active={active}
                                            preferredAlignment="right"
                                            activator={
                                                <Button
                                                    primary
                                                    onClick={() => setActive(!active)}
                                                    icon={ChevronDownMinor}
                                                    accessibilityLabel="Add Language"
                                                ></Button>
                                            }
                                            onClose={() => setActive(false)}
                                        >
                                            <div className="multiselectlang">
                                                <div
                                                    style={{ padding: "16px" }}
                                                    className="mt-2 p-0"
                                                >
                                                    <ResourceList
                                                        items={languages.sort()}
                                                        renderItem={(item) => (
                                                            <ResourceList.Item key={item} onClick={() => toggleLanguage(item)}>
                                                                <Checkbox
                                                                    label={item}
                                                                    checked={selectedLanguages.includes(item)}
                                                                    onChange={() => toggleLanguage(item)}
                                                                />
                                                            </ResourceList.Item>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                        </Popover>
                                    </div>
                                </ButtonGroup>
                            </div>
                        )}
                    </div>
                </VerticalStack>
            </HorizontalGrid>


            {/* modal */}
            <ModalCommon
                width={false}
                title={language}
                open={openModal}
                cancle="Cancel"
                confirmation="Save"
                disableConfirmation={isDisabledFlag}
                cancleHandleChange={OpenDeleteModal}
                destructive={false}
                confirmHandle={saveLanguage}
                body={
                    <>
                        <TextField
                            placeholder='Get more for less!'
                            label="Offer Text"
                            value={languageData?.offer_text}
                            onChange={(value) => {
                                setLanguageData({
                                    ...languageData,
                                    offer_text: value.trimStart()
                                })
                            }}
                            labelAction={{
                                content: (
                                    <Tooltip content="Entice your buyer to take this offer. The text would appear at the top of this offer page.">
                                        <Icon source={QuestionMarkInverseMinor} color="base" />
                                    </Tooltip>
                                ),
                            }}
                            autoComplete="off"
                        />
                        <div className="mt-2">
                            <Checkbox
                                label={
                                    <>
                                        <div className="d-flex">
                                            Show offer description{" "}
                                            <span className="ps-1">
                                                <Tooltip content="Add a short and sweet offer description. This would show below the product variants and images.">
                                                    <Icon source={QuestionMarkInverseMinor} color="base" />
                                                </Tooltip>
                                            </span>
                                        </div>
                                    </>
                                }
                                checked={languageData.show_offer_description}
                                onChange={(value) =>
                                    setLanguageData({
                                        ...languageData,
                                        show_offer_description: value
                                    })
                                }
                            />
                            {languageData.show_offer_description && (
                                <div className="mt-2">
                                    <TextField
                                        placeholder='For example: Keep the eco-friendly vibe going. This product is made from 100% organic recyclable materials. Get the total green look now.'
                                        value={languageData?.offer_description}
                                        onChange={(value) => {
                                            setLanguageData({
                                                ...languageData,
                                                offer_description: value.trimStart()
                                            })
                                        }
                                        }
                                        multiline={3}
                                        autoComplete="off"
                                    />
                                </div>
                            )}
                        </div>
                    </>
                }
            />
        </>
    )
}

export default UpsellOfferText
