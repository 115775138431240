/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Card, DatePicker, Icon, Popover, TextField, VerticalStack } from "@shopify/polaris";
import {  useEffect, useState } from "react";
import {
  CalendarMinor
} from '@shopify/polaris-icons';
import { useDispatch, useSelector } from "react-redux";
import { updateSelectedFunnel } from "../Redux/features/NewFunnel/newfunnelSlice";


export function DatePickerCommon({ time, oldSelectedDate }) {
  const dispatch = useDispatch();
  const selectedIndex$ = useSelector((state) => state?.newfunnel?.seleted_step);
  const Trigger$ = useSelector((state) => state?.newfunnel?.data)
  const [visible, setVisible] = useState(false);
  const [selectStartDate, setSelectStartDate] = useState(Trigger$?.[selectedIndex$]?.start_time);
  const [selectEndDate, setSelectEndDate] = useState(Trigger$?.[selectedIndex$]?.end_time);
  const monthNames = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ];
  const formaValueHandler = (date) => {
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();
    return `${day} ${monthNames[month]} ${year}`
  }
  
  const [{ month, year }, setDate] = useState({
    month: selectStartDate?.getMonth(),
    year: selectStartDate?.getFullYear(),
  });

  const today = new Date();
  const yesterday = new Date(today); 
  yesterday.setDate(today.getDate() - 1); 

  useEffect(() => {
    if(oldSelectedDate !== null && oldSelectedDate !== undefined){
      if(Trigger$[selectedIndex$].start_time > Trigger$[selectedIndex$].end_time){
        let updatedFields = {
          "end_time": Trigger$[selectedIndex$].start_time,
        }
        dispatch(updateSelectedFunnel(updatedFields))
        setSelectEndDate(Trigger$[selectedIndex$].end_time);
      }
    }
  },[oldSelectedDate])

  function handleOnClose({ relatedTarget }) {
    setVisible(false);
  }

  function handleMonthChange(month, year) {
    setDate({ month, year });
  }

  function handleDateSelection(typeDate, { end: newSelectedDate }) {
    if (typeDate === "startTime") {
      let updatedFields = {
        "start_time": newSelectedDate,
      }
      dispatch(updateSelectedFunnel(updatedFields))
      setSelectStartDate(newSelectedDate);
      setVisible(false);
    }
    if (typeDate === "endTime") {
      let updatedFields = {
        "end_time": newSelectedDate
      }
      dispatch(updateSelectedFunnel(updatedFields))
      setSelectEndDate(newSelectedDate);
      setVisible(false);
    }
  }

  useEffect(()=>{
    if(time === 'end_time'){
      setSelectEndDate(Trigger$[selectedIndex$].end_time)
    }
  },[Trigger$[selectedIndex$].end_time])

  return (
    <VerticalStack inlineAlign="center" gap="4">
      <Box minWidth="100%">
        <Popover
          active={visible}
          autofocusTarget="none"
          preferredAlignment="left"
          fullWidth
          preferInputActivator={false}
          preferredPosition="below"
          preventCloseOnChildOverlayClick
          onClose={handleOnClose}
          activator={
            <TextField
              role="combobox"
              prefix={<Icon source={CalendarMinor} />}
              value={time === "start_time" ? formaValueHandler(selectStartDate) : formaValueHandler(selectEndDate)}
              onFocus={() => setVisible(true)}
              // onChange={handleInputValueChange}
              autoComplete="off"
            />
          }
        >

          <Card >
            {
              time === "start_time" ? <>
                <DatePicker
                  disableDatesBefore={yesterday}
                  month={month}
                  year={year}
                  selected={selectStartDate}
                  onMonthChange={handleMonthChange}
                  onChange={(newSelectedDate) => { handleDateSelection("startTime", newSelectedDate) }}
                />
              </> :
                <DatePicker
                  disableDatesBefore={new Date(Trigger$[selectedIndex$]?.start_time)}
                  month={month}
                  year={year}
                  selected={selectEndDate}
                  onMonthChange={handleMonthChange}
                  onChange={(newSelectedDate) => { handleDateSelection("endTime", newSelectedDate) }}
                />
            }
          </Card>
        </Popover>
      </Box>
    </VerticalStack>
  )
}


