/* eslint-disable react-hooks/exhaustive-deps */
import { Button, HorizontalStack, Page, Text, Frame, Loading } from '@shopify/polaris'
import React, { useEffect, useState } from 'react'
import { FunnelListingTable } from './FunnelListingTable';
import axios from "../../../Helper/axios"
import { SERVER_BASE_URL } from '../../../config';
import { useNavigate } from "@shopify/app-bridge-react"
import { Link } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { FunnelList } from '../../../Redux/features/Funnel/funnelSlice';
import LoginRequired from '../../../Common/LoginRequired';


export default function Funnel() {

    const [isLoaded, setIsLoaded] = useState(false);
    const auth_flow_link = `${SERVER_BASE_URL}/auth/login${window.location.search}`
    const dispatch = useDispatch();
    const navigate = useNavigate()

    const [redirectionRequired, setRedirectionRequired] = useState(false)

    useEffect(() => {
        axios({
            method: "get",
            baseURL: SERVER_BASE_URL,
            url: "/api/funnels-list/",
            // url: "shopify/get-products",
        }).then(
            (result) => {
                if (result.data.login_required) {
                    alert("Please Login Again.")

                    setRedirectionRequired(true)
                    // navigate(auth_flow_link)
                    // window.location.href.replace(auth_flow_link);
                    // window.location.replace(auth_flow_link)

                } else {
                }
                setIsLoaded(true);
                dispatch(FunnelList(result?.data));
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
                setIsLoaded(true);
            }
        );
    }, []);


    if (!redirectionRequired) {

        return (
            <>
                <div className="main_layout_page">
                    <Page fullWidth>
                        <div className='top_head_nav'>
                            <HorizontalStack align="space-between" blockAlign='center'>
                                <Text variant="headingLg" as="h5" fontWeight='semibold'>
                                    Funnels
                                </Text>
                                <Link onClick={() => { navigate("/funnels/new") }} primary>
                                    <Button primary>
                                        New Funnel
                                    </Button>
                                </Link>
                            </HorizontalStack>
                        </div>

                        {isLoaded ?
                            <FunnelListingTable />
                            : <Frame>
                                <Loading />
                            </Frame>
                        }
                    </Page>
                </div>
            </>
        )
    }
    // If shop data has been deleted then.
    else {
        return (
            <LoginRequired auth_flow_link={auth_flow_link} />

        )
    }

}
