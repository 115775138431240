import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  data: [
    {
      funnel_name: "",
      funnel_page: "",
      offer_type: "cross_sell",
      follow_up_action: "checkout_page",
    },
    {
      show_for_all: false,
      show_for_specific_product: false,
      products_list: [],
      upsell_product_detail: null,
      exclude_variants: false,
      excluded_variants_list: [],
      show_for_specific_collection: false,
      collections_list: [],
      do_not_show_for_specific_product: false,
      do_not_show_for_specific_products_list: [],
      do_not_show_for_specific_collection: false,
      do_not_show_for_specific_collections_list: [],
      devices_to_show_on: "both",
      target_countries_status: false,
      target_countries: [],
      funnel_priority_status: false,
      funnel_priority: 1,
      start_time_status: false,
      start_time: new Date(),
      end_time_status: false,
      end_time: new Date(),
    },
    {
      offer: []
    },
  ],
  seleted_step: 0,
  offer_type: null,
  selectedTab: 0
};

export const newfunnelSlice = createSlice({
  name: "newfunnel",
  initialState,
  reducers: {
    setOfferSource: (state, action) => {
      state.offer_type = action.payload;
    },
    setSelectedTab: (state, action) => {
      state.selectedTab = action.payload;
    },
    defaultUpsellData: (state, action) => {
      state.data[2].offer = action.payload;
    },
    editFunnel: (state, action) => {
      state.data = action.payload;
    },
    updateSelectedFunnel: (state, action) => {
      const updatedFields = action.payload;
      state.data[state?.seleted_step] = {
        ...state.data[state?.seleted_step],
        ...updatedFields,
      };
    },
    updatesetUpFunnel: (state, action) => {
      const { offer_number, updatedFields, disFrom } = action.payload;
      const newData = updatedFields.sort((a, b) => a.offer_number - b.offer_number);
      const index = newData.findIndex(item => item.offer_number === offer_number + 1 && item.offer_name === null)
      if (index !== -1) {
        if (disFrom !== 'acceptedBtn') {
          newData.splice(index, 1);
        }
      }
      state.data[2].offer = [...newData];
    },
    addUpsellOfferData: (state, action) => {
      const { updatedFields } = action.payload;
      state.data[2].offer = updatedFields;
    },
    deleteupdateFunnel: (state, action) => {
      const { offer_number } = action.payload
      const nextItem = state.data[2].offer.find((item) => item.offer_number === offer_number + 1);
      const prevItem = state.data[2].offer.find((item) => item.offer_number === offer_number - 1);
      if (nextItem) {
        state.data[2].offer = state.data[2].offer.filter((item, index) => item?.offer_number !== offer_number);
        const updateItem = state.data[2].offer.map((item, index) => {
          const upadteData = { ...item };
          if (item.offer_number === prevItem?.offer_number) {
            if (nextItem?.offer_name !== null) {
              upadteData.hasDownsellCondition = true
            } else {
              upadteData.hasDownsellCondition = false
            }
          }
          if (item.offer_number === nextItem?.offer_number) {
            upadteData.offer_number = nextItem?.offer_number - 1
          }
          return upadteData;
        });
        state.data[2].offer = [...updateItem];
      } else {
        state.data[2].offer = state.data[2].offer.filter((item) => item?.offer_number !== offer_number);
        const updateItem = state.data[2].offer.map((item) => {
          const upadteData = { ...item };
          if (item?.offer_number === 2) {
            upadteData.hasDownsellCondition = false;
          }
          return upadteData;
        });
        state.data[2].offer = [...updateItem];
      }

      if (state.data[2].offer.length === 1) {
        state.data[2].offer[0].hasDownsellCondition = false;
      }
    },
    updateOfferData: (state, action) => {
      const { offer_number, offer_name, updatedFields } = action.payload;
      const NewData = state.data[2].offer.map((item) => {
        if (item.offer_name === offer_name && item.offer_number === offer_number) {
          return {
            ...item,
            ...updatedFields,
          };
        } else {
          return item;
        }
      });
      state.data[2].offer = [...NewData]
    },
    deleteupdateOfferData: (state, action) => {
      const { offer_number, offer_name, updatedFields, name, upsell } = action.payload;
      const updatedOfferData = state.data[2].offer.map(item => {
        if (item.offer_name === offer_name && item.offer_number === offer_number) {
          if (name === "Offer_product") {
            const updatedProductIds = item.product_id.filter((item) => item.id !== updatedFields);
            return {
              ...item,
              product_id: updatedProductIds
            };
          }
          if (name === "Offer_Variants") {
            const updatedProductIds = item.exclude_product_variants_list.filter((item) => item.id !== updatedFields);
            return {
              ...item,
              exclude_product_variants_list: updatedProductIds
            };
          }
          if (name === "language_delete" && upsell !== 'upsell_delete_language') {
            const updatedProductIds = item.language.filter((item) => item.language !== updatedFields);
            return {
              ...item,
              language: updatedProductIds
            };
          }
          if (name === 'language_delete' && upsell === 'upsell_delete_language') {
            const updatedProductIds = item?.language?.filter((item) => item.language !== updatedFields);
            return {
              ...item,
              language: updatedProductIds
            };
          }
          if (name === "variant_trigger") {
            const updatedProductIds = item?.variant_trigger?.filter((item) => item.id !== updatedFields);
            return {
              ...item,
              variant_trigger: updatedProductIds
            };
          }
          if (name === "variant_upsell") {
            const updatedProductIds = item?.variant_upsell?.filter((item) => item.id !== updatedFields);
            return {
              ...item,
              variant_upsell: updatedProductIds
            };
          }
          if (name === "upsell_product") {
            const updatedProductIds = item?.upsell_product?.filter((item) => item.id !== updatedFields);
            return {
              ...item,
              upsell_product: updatedProductIds
            };
          }
          if (name === "upsell_product_repeat") {
            const updatedProductIds = item?.upsell_product?.filter((item) => item.admin_graphql_api_id !== updatedFields);
            return {
              ...item,
              upsell_product: updatedProductIds
            };
          }
        }
        return item;
      });
      state.data[2].offer = updatedOfferData;
    },
    selectedStep: (state, action) => {
      state.seleted_step = action.payload;
    },
    Updateupsell: (state, action) => {
      state.data[1] = {
        ...state.data[1],
        ...action.payload,
      };
    },
    ProductList: (state, action) => {
      state.data[state?.seleted_step].products_list = action.payload;
    },
    deleteProduct: (state, action) => {
      state.data[state?.seleted_step].products_list = state.data[
        state?.seleted_step
      ].products_list.filter((item, index) => item?.id !== action.payload);
    },
    collectionsList: (state, action) => {
      state.data[state?.seleted_step].collections_list = action.payload;
    },
    deletecollections: (state, action) => {
      state.data[state?.seleted_step].collections_list = state.data[
        state?.seleted_step
      ].collections_list.filter((item, index) => item?.id !== action.payload);
    },
    FunnelRulesProductList: (state, action) => {
      state.data[1].do_not_show_for_specific_products_list = action.payload;
    },
    deleteFunnelRulesProductList: (state, action) => {
      state.data[state?.seleted_step].do_not_show_for_specific_products_list =
        state.data[
          state?.seleted_step
        ].do_not_show_for_specific_products_list.filter(
          (item, index) => item?.id !== action.payload
        );
    },
    FunnelRulescollections: (state, action) => {
      state.data[1].do_not_show_for_specific_collections_list = action.payload;
    },
    deleteFunnelRulescollections: (state, action) => {
      state.data[
        state?.seleted_step
      ].do_not_show_for_specific_collections_list = state.data[
        state?.seleted_step
      ].do_not_show_for_specific_collections_list.filter(
        (item, index) => item?.id !== action.payload
      );
    },
    devices_showon: (state, action) => {
      state.data[state?.seleted_step].devices_to_show_on = action.payload;
    },
    deletetargetcountries: (state, action) => {
      state.data[state?.seleted_step].target_countries = state.data[
        state?.seleted_step
      ].target_countries.filter((item, index) => item?.code !== action.payload);
    },
    targetcountries: (state, action) => {
      state.data[state?.seleted_step].target_countries = action.payload;
    },
    starttime: (state, action) => {
      state.data[state?.seleted_step].start_time = action.payload;
    },
    variantslist: (state, action) => {
      state.data[1].excluded_variants_list = action.payload;
    },
    deletevariantslist: (state, action) => {
      state.data[state?.seleted_step].excluded_variants_list = state.data[state?.seleted_step].excluded_variants_list.filter(
        (item, index) => item?.id !== action.payload
      );
    },
    upsellproductdetail: (state, action) => {
      state.data[1].upsell_product_detail = action.payload;
    },
  },
});

export const {
  setOfferSource,
  setSelectedTab,
  defaultUpsellData,
  editFunnel,
  updateSelectedFunnel,
  updatesetUpFunnel,
  addUpsellOfferData,
  selectedStep,
  ProductList,
  deleteProduct,
  collectionsList,
  deletecollections,
  FunnelRulesProductList,
  deleteFunnelRulesProductList,
  FunnelRulescollections,
  deleteFunnelRulescollections,
  devices_showon,
  targetcountries,
  starttime,
  deletetargetcountries,
  setData,
  variantslist,
  deletevariantslist,
  deleteupdateFunnel,
  updateOfferData,
  deleteupdateOfferData,
  upsellproductdetail,
  Updateupsell,
} = newfunnelSlice.actions;

export default newfunnelSlice.reducer;
