/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState } from 'react';
import {
    IndexTable,
    Text,
    Icon,
    ButtonGroup,
    Button,
    Link,
    Checkbox,
    Tooltip,
} from "@shopify/polaris";
import {
    AnalyticsMinor,
    ViewMinor,
    EditMinor,
    DuplicateMinor,
    DeleteMinor,
} from "@shopify/polaris-icons";
import axios from "../../../Helper/axios"
import { SERVER_BASE_URL } from "../../../config";
import ModalCommon from "../../../Common/ModalCommon";
import { useDispatch, useSelector } from 'react-redux';
import { UpdateFunnel, deleteFunnel } from '../../../Redux/features/Funnel/funnelSlice';
import { useNavigate } from "@shopify/app-bridge-react"
import ToastCommon from '../../../Common/ToastCommon';

export function FunnelListingTable() {
    const navigate = useNavigate()

    const funnel$ = useSelector((state) => state?.funnel?.data);
    const dispatch = useDispatch();
    const [loading, setloading] = useState(false)
    const [toaststatus, setToastStatus] = useState(false)
    const [toastmsg, setToastMSg] = useState("")


    const [deleteFunnelId, setDeleteFunnelId] = useState("");
    const [deleteFunnelName, setDeleteFunnelName] = useState("");

    //Open modal
    const [openModal, setOpenModal] = useState(false);
    const openDeleteModal = useCallback(
        (id, funnel_name) => {
            setDeleteFunnelId(id);
            setDeleteFunnelName(funnel_name);
            setOpenModal(!openModal);
        },
        [openModal, deleteFunnelId]
    );

    const confirmFunnelDelete = useCallback(() => {
        setloading(true)
        axios
            .delete(SERVER_BASE_URL + "/api/funnel/" + deleteFunnelId + "/")
            .then(function (response) {
                setToastStatus(response?.data?.success)
                setToastMSg(response?.data?.msg)
                dispatch(deleteFunnel(deleteFunnelId))
                setOpenModal(!openModal);
                setloading(false)
            })
            .catch(function (error) {
            })

    }, [funnel$, deleteFunnelId]);


    // update funnel list
    const handleChange = useCallback((newChecked, id) => {
        // Update the is_active status of the corresponding funnel
        const updatedFields = { is_active: newChecked };
        dispatch(UpdateFunnel({ id, updatedFields }))
        axios
            // .patch(SERVER_BASE_URL + "/api/funnel/" + id + "/", {
            //     "funnel": { is_active: newChecked }
            // })
            .patch(SERVER_BASE_URL + "/api/funnel/" + id + "/", {
                "is_active": newChecked
            })
            .then(function (response) {
                //Show toast (optional)
                setToastStatus(response?.data?.success)
                setToastMSg(response?.data?.msg)
            })
            .catch(function (error) {
                const updatedFields = { is_active: !newChecked };
                dispatch(UpdateFunnel({ id, updatedFields }))
            });
    }, [funnel$]);

    const resourceName = {
        singular: "funnel",
        plural: "funnels",
    };

    const rowMarkup = funnel$?.length > 0 && funnel$?.map(
        (
            {
                id,
                funnel_name,
                is_active,
                placement_page,
                offer_type,
                funnel_priority,
                funnel_views,
                funnel_sales,
                funnel_conversion,
                currency,
                funnel_revenue,
            },
            index
        ) => (
            <IndexTable.Row id={id} key={id} position={index}>
                <IndexTable.Cell>
                    <Text variant="bodyMd" fontWeight="bold" as="span">
                        {funnel_name}
                    </Text>
                </IndexTable.Cell>
                <IndexTable.Cell>
                    <span className="switch">
                        <Checkbox
                            checked={is_active ? is_active : false}
                            onChange={(newChecked) => handleChange(newChecked, id)}
                        />
                    </span>
                </IndexTable.Cell>
                <IndexTable.Cell>{placement_page}</IndexTable.Cell>
                <IndexTable.Cell>{offer_type}</IndexTable.Cell>
                <IndexTable.Cell>{funnel_priority}</IndexTable.Cell>
                <IndexTable.Cell>{funnel_views ? funnel_views : 0}</IndexTable.Cell>
                <IndexTable.Cell>{funnel_sales}</IndexTable.Cell>
                <IndexTable.Cell>{funnel_conversion.toFixed(2)} %</IndexTable.Cell>
                <IndexTable.Cell>{currency} {funnel_revenue}</IndexTable.Cell>
                <IndexTable.Cell>
                    <div className="btn_grp">
                        <ButtonGroup segmented>
                            <Link onClick={() => { navigate(`/funnel/${id}/analytics`) }} >
                                <Button>
                                    <Tooltip content="Funnel Analytics">
                                        <Icon source={AnalyticsMinor} color="base" />
                                    </Tooltip>
                                </Button>
                            </Link>

                            {/* <Link onClick={() => { navigate(`/funnel/${id}/view`) }} > */}
                            <Link >
                                <Button disabled={true} >
                                    <Tooltip content="Funnel Design">
                                        <Icon source={ViewMinor} color="base" />
                                    </Tooltip>
                                </Button>
                            </Link>
                            <Link onClick={() => { navigate(`/funnel/${id}/edit`) }}>
                                <Button>
                                    <Tooltip content="Funnel Edit">
                                        <Icon source={EditMinor} color="base" />
                                    </Tooltip>
                                </Button>
                            </Link>
                            <Link onClick={() => { navigate(`/funnel/${id}/duplicate`) }}>
                                <Button>
                                    <Tooltip content="Funnel Duplicate">
                                        <Icon source={DuplicateMinor} color="base" />
                                    </Tooltip>
                                </Button>

                            </Link>
                            {/* <Button>
                                <Icon source={DuplicateMinor} color="base" />
                            </Button> */}
                            <Button onClick={() => openDeleteModal(id, funnel_name)}>
                                <Tooltip content="Funnel Delete">
                                    <Icon source={DeleteMinor} color="base" />
                                </Tooltip>
                            </Button>
                        </ButtonGroup>
                    </div>
                </IndexTable.Cell>
            </IndexTable.Row>
        )
    );

    return (
        <>
            <IndexTable
                resourceName={resourceName}
                itemCount={funnel$?.length ? funnel$?.length : 0}
                headings={[
                    { title: "Funnel Name" },
                    { title: "Status" },
                    { title: "Placement Page" },
                    { title: "Offer Type" },
                    { title: "Priority" },
                    { title: "Views" },
                    { title: "Sales" },
                    { title: "Conversion" },
                    { title: "Revenue" },
                    { title: "Funnel Actions" },
                ]}
                selectable={false}
            >
                {rowMarkup}
            </IndexTable>
            <ModalCommon
                loading={loading}
                width={false}
                title={"Delete Funnel"}
                open={openModal}
                cancle="Cancel"
                confirmation="Delete"
                cancleHandleChange={openDeleteModal}
                destructive={true}
                confirmHandle={confirmFunnelDelete}
                body={
                    <div>
                        Are you sure you want to delete <b>{deleteFunnelName}</b>?
                    </div>
                }
            />
            {
                toaststatus && (
                    <ToastCommon
                        status={toaststatus}
                        msg={toastmsg}
                        setToastStatus={setToastStatus}
                        error={false}
                        time={1500}
                    />
                )
            }

        </>
    );
}
