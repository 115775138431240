/* eslint-disable react-hooks/exhaustive-deps */
import { Checkbox, HorizontalStack, Icon, Modal, Spinner, TextField, Thumbnail } from "@shopify/polaris";
import React, { useCallback, useEffect, useState } from "react";
import { SearchMinor } from "@shopify/polaris-icons";
import { ImageMajor } from '@shopify/polaris-icons';
import { useDispatch, useSelector } from "react-redux";
import { FunnelRulesProductList, FunnelRulescollections, updateOfferData, variantslist } from "../../../../Redux/features/NewFunnel/newfunnelSlice";
import { setProductUpsell, setVariantUpsell } from "../../../../Redux/features/NewFunnel/upsellTabsSlice";

export default function ProductModal({ modalName, open, cancle, data, loading, item, type = null }) {
  const dispatch = useDispatch();
  const itemDataAPI = useSelector((state) => state?.newfunnel?.data);
  // search
  const [value, setValue] = useState("");
  const handleChange = useCallback((newValue) => setValue(newValue), []);
  const SerchItem = data?.filter((item) => {
    return item?.title?.toLowerCase().includes(value?.toLowerCase());
  });

  // checkbox
  const [itemCheck, setItemCheck] = useState([]);

  const [disableCheckbox, setDisableCheckbox] = useState({
    is_disable: false,
    id: ''
  });

  const [findIndex, setFindIndex] = useState(-1);

  const checkboxChange = useCallback((item) => {
    const currentItems = Array.isArray(itemCheck) ? itemCheck : [];

    const itemIndex = currentItems.findIndex((existingItem) => existingItem.id === item.id);
    setFindIndex(itemIndex);

    if (itemIndex === -1) {
      setItemCheck([...currentItems, item]);
    } else {
      const updatedItemCheck = [...currentItems];
      updatedItemCheck.splice(itemIndex, 1);
      setItemCheck(updatedItemCheck);
      setDisableCheckbox({ ...disableCheckbox, is_disable: false, id: '' });
    }
  }, [itemCheck, disableCheckbox, findIndex]);



  // disabled last variant
  useEffect(() => {
    if (modalName === 'Variants' && (type !== "variant_trigger" && type !== "variant_upsell")) {
      if (findIndex === -1) {
        if (itemCheck?.length === SerchItem.length - 1) {
          const itemsNotInMain = SerchItem.filter(otherItem => !itemCheck?.some(mainItem => mainItem.id === otherItem.id));
          setDisableCheckbox({ ...disableCheckbox, is_disable: true, id: itemsNotInMain[0]?.id })
        } else {
          setDisableCheckbox({ ...disableCheckbox, is_disable: false, id: '' })
        }
      }
    }
  }, [itemCheck, modalName, type])

  // cancle action
  const cancleAction = () => {
    cancle();
  }

  useEffect(() => {
    if (modalName === 'Products') {
      setItemCheck(itemDataAPI[1].do_not_show_for_specific_products_list)
    }
    if (modalName === 'Products' && type === 'upsell_product') {
      setItemCheck(itemDataAPI[2].offer[0].upsell_product)
    }
    if (modalName === 'Collection') {
      setItemCheck(itemDataAPI[1].do_not_show_for_specific_collections_list)
    }
    if (modalName === 'Variants') {
      setItemCheck(itemDataAPI[1].excluded_variants_list)
    }
    if (type === 'Offer_Variants') {
      setItemCheck(item?.exclude_product_variants_list)
    }
    if (type === 'variant_trigger') {
      setItemCheck(item?.variant_trigger)
    }
    if (type === 'variant_upsell') {
      setItemCheck(item?.variant_upsell)
    }
  }, [modalName])

  // select();
  const selectAction = () => {
    if (modalName === 'Products') {
      dispatch(FunnelRulesProductList(itemCheck));
      cancle();
    }
    if (modalName === 'Collection') {
      dispatch(FunnelRulescollections(itemCheck));
      cancle();
    }
    if (modalName === 'Variants' && (type !== "Offer_Variants")) {
      dispatch(variantslist(itemCheck));
      cancle();
    }
    if (type === 'Offer_Variants') {
      const updatedFields = {
        "exclude_product_variants_list": itemCheck,
      };
      // dispatch(variantslist(itemCheck));
      dispatch(updateOfferData({ offer_number: item?.offer_number, offer_name: item?.offer_name, updatedFields }));

      cancle();
    }
    if (type === 'variant_trigger' && modalName === 'Variants') {
      const updatedFields = {
        "variant_trigger": itemCheck,
      };
      dispatch(updateOfferData({ offer_number: item?.offer_number, offer_name: item?.offer_name, updatedFields }));
      const newData = {
        // offer_name: null,
        offer_number: 1,
        upsell_product: [],
        offer_text: "",
        language: [],
        show_different_language: false,
        show_offer_description: false,
        description: "",
        include_free_shipping: false,
        exclude_variants: false,
        exclude_product_variants_list: [],
      }
      dispatch(setProductUpsell([newData]))

      cancle();
    }
    if (type === 'variant_upsell' && modalName === 'Variants') {
      const updatedFields = {
        "variant_upsell": itemCheck,
      };
      dispatch(updateOfferData({ offer_number: item?.offer_number, offer_name: item?.offer_name, updatedFields }));
      cancle();
    }
    if (type === 'upsell_product' && modalName === 'Products') {
      const updatedFields = {
        "upsell_product": itemCheck,
      };

      dispatch(updateOfferData({ offer_number: item?.offer_number, offer_name: item?.offer_name, updatedFields }))

      const newData = {
        // offer_name: null,
        offer_number: 1,
        variant_trigger: [],
        variant_upsell: [],
        offer_text: "",
        show_different_language: false,
        language: [],
        show_offer_description: false,
        description: "",
        include_free_shipping: false,
      }
      dispatch(setVariantUpsell([newData]))
      const varintsUpdate = {
        "exclude_product_variants_list": [],
      };
      dispatch(updateOfferData({ offer_number: item?.offer_number, offer_name: item?.offer_name, updatedFields: varintsUpdate }));
      cancle();
    }
  }


  return (
    <Modal
      open={open}
      onClose={cancleAction}
      title={`Select ${modalName}`}
      primaryAction={{
        content: "Select",
        onAction: selectAction,
        disabled: (itemDataAPI[1]?.do_not_show_for_specific_products_list?.length > 0 ? false : itemCheck?.length <= 0 ? true : false)
        // disabled: (itemCheck.length <= 0)
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: cancleAction,
        },
      ]}
      footer={
        <p>{itemCheck?.length} {modalName} selected</p>
      }
    >
      <Modal.Section>
        <div className="product_modal_wrap">

          {/* search products */}
          <div className="search_product_wrap">
            {
              !loading && <TextField
                placeholder={`Search ${modalName}`}
                value={value}
                onChange={handleChange}
                autoComplete="off"
                prefix={<Icon source={SearchMinor} color="base" />}
                disabled={loading}
              />
            }

          </div>

          {
            loading ?
              <div className="my-2">
                <HorizontalStack blockAlign="center" align="center" className="my-2">
                  <Spinner accessibilityLabel="Spinner example" size="large" />
                </HorizontalStack>
              </div> : (
                <div className="product_list_main_wrap">

                  {/* product list */}
                  <div className="product_list">
                    {
                      SerchItem?.map((item, index) => {

                        return (
                          <div className="product_list_col" key={index}>
                            {/* checkbox */}
                            <div className="checkbox_wrap">
                              <Checkbox
                                key={item.id}
                                checked={
                                  itemCheck?.some((selectedItem) => selectedItem.id === item.id) &&
                                  !(modalName === 'Products' ?
                                    (itemDataAPI[0]?.offer_type === "upsell" ?
                                      itemDataAPI[1]?.upsell_product_detail?.id === item?.admin_graphql_api_id :
                                      itemDataAPI[1]?.products_list.some((items) => items?.id === item?.admin_graphql_api_id) &&
                                      itemDataAPI[1]?.show_for_specific_product
                                    ) :
                                    modalName === 'Collection' ?
                                      (itemDataAPI[1]?.collections_list.some((items) => items?.id === item?.admin_graphql_api_id) &&
                                        itemDataAPI[1]?.show_for_specific_collection) :
                                      disableCheckbox?.is_disable ? item?.id !== disableCheckbox?.id ? false : true : ''
                                  )
                                }


                                onChange={() => checkboxChange(item)}

                                disabled={
                                  modalName === 'Products' && type !== "upsell_product"
                                    ? itemDataAPI[0]?.offer_type === "upsell"
                                      ? itemDataAPI[1]?.upsell_product_detail?.id === item?.admin_graphql_api_id
                                      : itemDataAPI[1]?.products_list.some((items) => items?.id === item?.admin_graphql_api_id) &&
                                      itemDataAPI[1]?.show_for_specific_product
                                    : modalName === 'Collection'
                                      ? itemDataAPI[1]?.collections_list.some((items) => items?.id === item?.admin_graphql_api_id) &&
                                      itemDataAPI[1]?.show_for_specific_collection
                                      : disableCheckbox?.is_disable
                                        ? item?.id === disableCheckbox?.id :
                                        (modalName === "Variants" && type === "variant_upsell" && itemCheck?.length > 0) ?
                                          item?.id !== itemCheck[0]?.id :
                                          (modalName === "Products" && type === "upsell_product" && itemCheck?.length > 0) ?
                                            item?.id !== itemCheck[0]?.id :
                                            false
                                }
                              />
                            </div>
                            {/* product name */}
                            <div className="product_info_wrap">
                              <Thumbnail source={item?.image?.src || ImageMajor} alt="Small document" />
                              <p>{item.title}</p>
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>
                  {/* product select */}
                  {/* <div className="product_selected_wrap">
                    <p>{itemCheck.length} product selected</p>
                  </div> */}

                  <div className="product_selected_wrap">
                    {
                      disableCheckbox.is_disable && (
                        <p>  You can't exclude all variants  </p>
                      )
                    }
                  </div>
                </div>
              )
          }
        </div>
      </Modal.Section>
    </Modal>
  );
}
