/* eslint-disable react-hooks/exhaustive-deps */
import { HorizontalGrid, Icon, Tag, Text, TextField, VerticalStack } from '@shopify/polaris'
import React, { useEffect, useState } from 'react'
import { QuestionMarkInverseMinor } from "@shopify/polaris-icons";
import ProductModal from '../../Product_Modal/ProductModal';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteupdateOfferData } from '../../../../../Redux/features/NewFunnel/newfunnelSlice';


const VariantTriggers = ({ item, index, funnelData, setUniqueData }) => {
    const variantData = useSelector(state => state?.newfunnel?.data[1]?.upsell_product_detail?.variants);
    const triggerData = funnelData?.flatMap(obj => obj?.variant_trigger);
    const [uniqueVariants, setUniqueVariants] = useState([]);

    useEffect(() => {
        const findUniqueObjects = (arr1, arr2) => {
            const uniqueInArr1 = arr1?.filter(obj1 => !arr2?.find(obj2 => obj2?.id === obj1?.id));
            const uniqueInArr2 = arr2?.filter(obj2 => !arr1?.find(obj1 => obj1?.id === obj2?.id));
            return uniqueInArr1?.concat(uniqueInArr2);
        }
        const uniqueVariantsData = findUniqueObjects(variantData, triggerData);
        setUniqueVariants(uniqueVariantsData);
        setUniqueData(uniqueVariantsData);
    }, [variantData, funnelData])

    useEffect(() => {

        if (item?.variant_trigger?.length > 0 && item?.variant_upsell?.length > 0) {
            const newUpsellData = item?.variant_trigger?.find((dataitem) => dataitem?.id === item?.variant_upsell[0]?.id);
            if (newUpsellData) {
                dispatch(deleteupdateOfferData({ offer_number: item?.offer_number, offer_name: item?.offer_name, updatedFields: newUpsellData?.id, name: "variant_upsell" }))
            }
        }

    }, [item?.variant_trigger])

    const dispatch = useDispatch();
    const [openVariantsPicker, setOpenVariantsPicker] = useState(false);

    const cancleVariant = useCallback(
        () => setOpenVariantsPicker(!openVariantsPicker),
        [openVariantsPicker]);

    const listSelectedproductTags =
        item.variant_trigger?.length > 0 ? (
            <div>
                {item.variant_trigger?.map((variant) => (
                    <Tag
                        onRemove={() => dispatch(deleteupdateOfferData({ offer_number: item?.offer_number, offer_name: item?.offer_name, updatedFields: variant.id, name: "variant_trigger" }))}
                        key={variant.id}
                    >
                        <div>
                            <span>{variant.title}</span>
                        </div>
                    </Tag>
                ))}
            </div>
        ) : null;

    return (
        <>
            <HorizontalGrid gap="4" columns={{ md: 2 }}>
                <VerticalStack>
                    <Text variant="headingMd" as="h5">
                        Variant trigger
                    </Text>
                    <p>Select variants to trigger this offer.</p>
                </VerticalStack>
                <VerticalStack>
                    <div className="mt-2 product_select">
                        {/* select product field */}
                        <div
                            onClick={() => {
                                setOpenVariantsPicker(true);
                            }}
                        >
                            <TextField
                                value=""
                                placeholder="Select Variants"
                                verticalContent={listSelectedproductTags}
                                labelAction={{
                                    content: (
                                        <Icon
                                            source={QuestionMarkInverseMinor}
                                            color="base"
                                        />
                                    ),
                                }}
                                autoComplete="off"
                            />
                        </div>
                    </div>
                </VerticalStack>
            </HorizontalGrid>

            {/* Select variants resource picker */}
            {
                openVariantsPicker &&
                <ProductModal modalName={'Variants'} open={openVariantsPicker} cancle={cancleVariant} data={uniqueVariants} item={item} type="variant_trigger" />
            }
        </>
    )
}

export default VariantTriggers



