import { Button, HorizontalStack, Page } from '@shopify/polaris'
import React from 'react'

const LoginRequired = ({ auth_flow_link }) => {
    return (
        <Page>
            <HorizontalStack align="center" blockAlign='center'>
                <a target='_blank' rel="noreferrer" href={auth_flow_link}>
                    <Button primary size="large"> Login again </Button>
                </a>
            </HorizontalStack>
        </Page>
    )
}

export default LoginRequired