import { ResourcePicker } from "@shopify/app-bridge-react";
import React from "react";

export default function MyResourcePicker({
  resourceType,
  openResourcePicker,
  hideResourcePicker,
  handleSelectProduct,
  selectedItems,
  selectMultiple = true,
}) {

  let initialSelectedIds = selectedItems?.map((selection) => ({
    id: selection.id,
  }));
  
  if (resourceType === "Product" || resourceType === "Collection" ) {
    return (
      <div>
        <ResourcePicker
          initialSelectionIds={initialSelectedIds}
          showHidden={false}
          showVariants={false}
          showArchived={false}
          resourceType={resourceType}
          open={openResourcePicker}
          onCancel={hideResourcePicker}
          onSelection={handleSelectProduct}
          selectMultiple={selectMultiple}
          actionVerb="select"
        />
      </div>
    );
  } else {
    
    return (
      <div>
        <ResourcePicker 
          showHidden={false}
          showVariants={false}
          showArchived={false}
          resourceType={"ProductVariant"}
          open={openResourcePicker}
          onCancel={hideResourcePicker}
          onSelection={handleSelectProduct}
          selectMultiple={selectMultiple}
          actionVerb="select"
        />
      </div>
    );
  }
}
