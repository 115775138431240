/* eslint-disable react-hooks/exhaustive-deps */
import { HorizontalGrid, Icon, RadioButton, Tag, Text, TextField, VerticalStack } from '@shopify/polaris'
import { QuestionMarkInverseMinor } from "@shopify/polaris-icons";

import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { deleteupdateOfferData, updateOfferData } from '../../../../../Redux/features/NewFunnel/newfunnelSlice';
import MyResourcePicker from '../../../../../Common/MyResourcePicker';

const SelectProduct = ({ item, index, setupHandleChange, funnelData }) => {

    //   const productlist = Trigger$[selectedIndex$].products_list;

    const dispatch = useDispatch();

    const handleRadioDataHandler = useCallback(
        (value, name) => {
            const radioData = {
                select_store_product: false,
                create_virtual_product: false,
            };
            dispatch(updateOfferData({ offer_number: item?.offer_number, offer_name: item?.offer_name, updatedFields: { ...radioData, [name]: value } }));
        },
        [funnelData]
    );

    //resource picker
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [openResourcePicker, setOpenResourcePicker] = useState(false);
    const hideResourcePicker = () => setOpenResourcePicker(false);
    const showResourcePicker = () => setOpenResourcePicker(true);

    const listSelectedproductTags =
        item.product_id?.length > 0 ? (
            <div>
                {item.product_id?.map((product) => (
                    <Tag
                        onRemove={() =>
                            handleRemoveProduct(
                                product.id,
                                dispatch(deleteupdateOfferData({ offer_number: item?.offer_number, offer_name: item?.offer_name, updatedFields: product.id, name: "Offer_product" }))
                            )
                        }
                        key={product.id}
                    >
                        <div>
                            <span>{product.title}</span>
                        </div>
                    </Tag>
                ))}
            </div>
        ) : null;

    // remove products
    const handleRemoveProduct = useCallback((productId) => {
        const updatedProducts = selectedProducts.filter(
            (product) => product.id !== productId
        );
        setSelectedProducts(updatedProducts);
        const varintsUpdate = {
            "exclude_product_variants_list": [],
        };
        dispatch(updateOfferData({ offer_number: item?.offer_number, offer_name: item?.offer_name, updatedFields: varintsUpdate }));
    }, [funnelData]);

    const handleSelectProduct = useCallback(
        ({ selection }) => {
            setSelectedProducts([...selection]);
            const updatedFields = {
                "product_id": [...selection],
            };
            dispatch(updateOfferData({ offer_number: item?.offer_number, offer_name: item?.offer_name, updatedFields }));
            item?.product_id?.map((e) => {
                if (e.id !== selection[0]?.id) {
                    const varintsUpdate = {
                        "exclude_product_variants_list": [],
                    };
                    dispatch(updateOfferData({ offer_number: item?.offer_number, offer_name: item?.offer_name, updatedFields: varintsUpdate }));
                }
                return null;
            })
            const productId = [];
            selection?.map((product) => (
                productId.push(product)
            ));
            hideResourcePicker();
        }, [selectedProducts, funnelData]);

    useEffect(() => {
        const productIds = funnelData?.map((e) => {
            if (item?.offer_number === e?.offer_number && item?.offer_name === e?.offer_name) {
                return e?.product_id[0];
            }
            return null
        }).filter(Boolean);
        setSelectedProducts(productIds);
    }, [funnelData]);

    return (
        <>
            <HorizontalGrid gap="4" columns={{ md: 2 }}>
                <VerticalStack>
                    <Text variant="headingMd" as="h5">
                        Select product
                    </Text>
                    <p>Select a product that will be offered to your buyer.</p>
                </VerticalStack>
                <VerticalStack>
                    <RadioButton
                        label="Select a store product"
                        checked={item?.select_store_product}
                        id={`store_product_${index}`}
                        name={`product_${index}`}
                        onChange={(value) => {
                            handleRadioDataHandler(value, "select_store_product");
                        }}
                    />

                    {item?.select_store_product && (
                        <div className="mt-2 product_select">
                            {/* select product field */}
                            <div
                                onClick={() => {
                                    setOpenResourcePicker(true);
                                }}
                            >
                                <TextField
                                    value=""
                                    placeholder="Select any Product"
                                    verticalContent={listSelectedproductTags}
                                    labelAction={{
                                        content: (
                                            <Icon
                                                source={QuestionMarkInverseMinor}
                                                color="base"
                                            />
                                        ),
                                    }}
                                    autoComplete="off"
                                />
                            </div>

                            {/* select product resource picker */}
                            <MyResourcePicker
                                resourceType={"Product"}
                                openResourcePicker={openResourcePicker}
                                hideResourcePicker={hideResourcePicker}
                                showResourcePicker={showResourcePicker}
                                handleSelectProduct={handleSelectProduct}
                                selectedItems={selectedProducts}
                                selectMultiple={false}
                            />
                        </div>
                    )}
                    {/* <div className="my-4">
                        <RadioButton
                            label={
                                <div className="d-flex">
                                    Create a virtual product
                                    <span className="ps-1">
                                        <Icon
                                            source={QuestionMarkInverseMinor}
                                            color="base"
                                        />
                                    </span>
                                </div>
                            }
                            id={`virtual_product_${index}`}
                            name={`product_${index}`}
                            checked={item?.create_virtual_product}
                            onChange={(value) => {
                                handleRadioDataHandler(value, "create_virtual_product");
                            }}
                        />
                    </div> */}
                </VerticalStack>
            </HorizontalGrid>
        </>
    )
}

export default SelectProduct