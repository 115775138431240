/* eslint-disable react-hooks/exhaustive-deps */
import { Checkbox, HorizontalGrid, Icon, Text, TextField, Tooltip, VerticalStack } from '@shopify/polaris'
import { QuestionMarkInverseMinor } from "@shopify/polaris-icons";
import React from 'react'
import { useCallback } from 'react';
import { updateOfferData } from '../../../../../Redux/features/NewFunnel/newfunnelSlice';
import { useDispatch } from 'react-redux';

const UpsellAdvanced = ({ item, index, funnelData }) => {
    const dispatch = useDispatch();

    const setupHandleChange = useCallback((value, name) => {
        const updatedFields = {
            [name]: value,
        };
        dispatch(updateOfferData({ offer_number: item?.offer_number, offer_name: item?.offer_name, updatedFields }));
    }, [funnelData]);

    const handleOfferTextChange = useCallback((value, name) => {
        const updatedFields = {
            [name]: value.trimStart(),
        };
        dispatch(updateOfferData({ offer_number: item?.offer_number, offer_name: item?.offer_name, updatedFields }));
    }, [funnelData]);

    return (
        <>
            <HorizontalGrid gap="4" columns={{ md: 2 }}>
                <VerticalStack>
                    <Text variant="headingMd" as="h5">
                        <div className="d-flex align-center">
                            Advanced
                            <span className="ps-1">
                                <Tooltip content="Advanced">
                                    <Icon source={QuestionMarkInverseMinor} color="base" />
                                </Tooltip>
                            </span>
                        </div>
                    </Text>
                    <p>Select a product that will be offered to your buyer.</p>
                </VerticalStack>

                <VerticalStack>

                    <div className="mt-2">
                        <Checkbox
                            label={
                                <>
                                    <div className="d-flex">
                                        Add offer description{" "}
                                        <span className="ps-1">
                                            <Tooltip content="Add a short and sweet offer description. This would show below the product variants and images.">
                                                <Icon source={QuestionMarkInverseMinor} color="base" />
                                            </Tooltip>
                                        </span>
                                    </div>
                                </>
                            }
                            checked={item?.show_offer_description}
                            onChange={(value) =>
                                setupHandleChange(value, "show_offer_description")
                            }
                        />
                        {item?.show_offer_description && (
                            <div className="mt-2">
                                <TextField
                                    placeholder='For example: Keep the eco-friendly vibe going. This product is made from 100% organic recyclable materials. Get the total green look now.'
                                    type="text"
                                    value={item?.description}
                                    multiline={3}
                                    autoComplete="off"
                                    onChange={(value) =>
                                        handleOfferTextChange(value, "description")
                                    }
                                />
                            </div>)
                        }



                    </div>

                    <div className="mt-2">
                        <Checkbox
                            label={
                                <>
                                    <div className="d-flex">
                                        Include free shipping for clients who accept this offer{" "}
                                        <span className="ps-1">
                                            <Tooltip content="Cart / product page funnels - apply free shipping on the entire order. Thank you page funnels - apply free shipping on all upsell products.">
                                                <Icon source={QuestionMarkInverseMinor} color="base" />
                                            </Tooltip>
                                        </span>
                                    </div>
                                </>
                            }
                            checked={item?.include_free_shipping}
                            onChange={(value) =>
                                setupHandleChange(value, "include_free_shipping")
                            }
                        />
                    </div>
                </VerticalStack>
            </HorizontalGrid>
        </>
    )
}

export default UpsellAdvanced
