/* eslint-disable react-hooks/exhaustive-deps */
import { Button, ButtonGroup, Divider, HorizontalStack, Icon, Text } from "@shopify/polaris";
import React, { useCallback, useState } from "react";
import { DeleteMajor, } from "@shopify/polaris-icons";
import iconsrc from "../../../../Assets/images/icons/hierarchy.svg";
import iconjoin from "../../../../Assets/images/icons/joinline.svg";
import { CirclePlusMinor } from "@shopify/polaris-icons";
import { useDispatch, useSelector } from "react-redux";
import { defaultUpsellData, deleteupdateFunnel, setOfferSource, updatesetUpFunnel } from "../../../../Redux/features/NewFunnel/newfunnelSlice";
import SelectProduct from "./partials/SelectProduct";
import OfferText from "./partials/OfferText";
import Advanced from "./partials/Advanced";
import { useEffect } from "react";
import { setcrosell } from "../../../../Redux/features/NewFunnel/upsellTabsSlice";

export default function SetupOffersmain() {
  const selectedIndex$ = useSelector((state) => state?.newfunnel?.seleted_step);
  const page = useSelector(state => state?.newfunnel?.data[0]?.funnel_page)
  const newFunnel$ = useSelector((state) => state?.newfunnel?.data[selectedIndex$]);
  const funnelData = newFunnel$?.offer
  const upsellTabData = useSelector((state) => state?.upsellTabs);
  const dispatch = useDispatch();

  const [data, setData] = useState([
    {
      id: 0,
      name: "offer #1",
      product: "",
      discount: "",
      discountProduct: "",
      checkOffer: false,
      checkDifferentLocales: false,
      freeShipping: false,
      limitNum: false,
      selectLimit: "",
      miniNum: false,
      minimalNumber: "",
      variants: false,
      personalisedProduct: false,
      offerText: "",
      showOfferCheck: false,
      showOfferDiscription: "",
    },
  ]);

  useEffect(() => {
    // if (!isEdit && !isDraft) {
      const newData = {
        offer_name: null,
        offer_number: 1,
        select_store_product: false,
        create_virtual_product: false,
        product_id: [],
        title: "",
        show_offer_description: false,
        description: "",
        maximum_limit: false,
        maximum_quantity_limit: 1,
        minimum_limit: false,
        minimum_quantity_limit: 1,
        exclude_variants: false,
        include_free_shipping: false,
        exclude_product_variants_list: [],
        language: [],
        show_different_language: false,
        hasDownsellCondition: false,
        is_active: true
      }
      // dispatch(setcrosell([newData]))
      let result = Array.isArray(upsellTabData?.crosell[0]);
      if (result && upsellTabData?.crosell[0]?.some((value) => (value?.product_id?.length > 0 || value?.title?.length > 0 || value?.description?.length > 0 || value?.maximum_quantity_limit >= 1 || value?.minimum_quantity_limit >= 1 || value?.exclude_product_variants_list?.length > 0 || value?.language?.length > 0 || value?.select_store_product || value?.create_virtual_product || value?.show_offer_description || value?.maximum_limit || value?.minimum_limit || value?.exclude_variants || value?.include_free_shipping || value?.show_different_language))) {
        dispatch(defaultUpsellData(upsellTabData?.crosell[0]))
      } else {
        dispatch(defaultUpsellData([newData]))
      }
      dispatch(setOfferSource("cross_sell"))
    // }
  }, [])
  useEffect(() => {
    if (funnelData) {
      dispatch(setcrosell([funnelData]))
    }
  }, [funnelData])

  const setupHandleChange = useCallback(
    (id, value, name) => {
      if (typeof value === "string") {
        if (value >= 1) {
          const updatedData = data.map((item) => {
            if (item?.id === id) {
              return { ...item, [name]: value };
            }
            return item;
          });

          setData(updatedData);
        }
      }
    },
    [data]
  );

  //handleAddField
  const [addField, setField] = useState(false);
  const handleAddField = () => {
    setField(true);
  };

  const handleDeleteField = () => {
    setField(false);
  };

  // offer delete
  const deleteoffer = (offer_number, offer_name) => {
    dispatch(deleteupdateFunnel({ offer_number: offer_number, offer_name: offer_name }));
  };

  const addDataHandler = (btnName, offerNumber, offerName) => {

    if (btnName === "addDownShell") {
      const updateItem = funnelData.map((item, index) => {
        const upadteData = { ...item }
        if (item.offer_number === offerNumber) {
          upadteData.hasDownsellCondition = true
        }
        return upadteData
      })

      // to check same downshell object is present or not 
      const flag = funnelData.some(item => item.offer_number === offerNumber + 1 && (item.offer_name === "A" || item.offer_name === "B"));
      if (!flag) {
        const setupOfffersDataA = {
          offer_name: "A",
          offer_number: offerNumber + 1,
          select_store_product: false,
          create_virtual_product: false,
          product_id: [],
          title: "",
          show_offer_description: false,
          description: "",
          maximum_limit: false,
          maximum_quantity_limit: 1,
          minimum_limit: false,
          minimum_quantity_limit: 1,
          exclude_variants: false,
          include_free_shipping: false,
          exclude_product_variants_list: [],
          language: null,
          show_different_language: false,
          hasDownsellCondition: false,
          is_active: true
        }
        const setupOfffersDataB = {
          offer_name: "B",
          offer_number: offerNumber + 1,
          select_store_product: false,
          create_virtual_product: false,
          product_id: [],
          title: "",
          show_offer_description: false,
          description: "",
          maximum_limit: false,
          maximum_quantity_limit: 1,
          minimum_limit: false,
          minimum_quantity_limit: 1,
          exclude_variants: false,
          include_free_shipping: false,
          exclude_product_variants_list: [],
          language: null,
          show_different_language: false,
          hasDownsellCondition: false,
          is_active: false
        }

        dispatch(updatesetUpFunnel({ offer_number: offerNumber, offer_name: offerName, updatedFields: [...updateItem, setupOfffersDataA, setupOfffersDataB] }));
      }
    }

    if (btnName === "acceptedBtn") {
      if (funnelData.length > 1) {
        const updatedFunnelData = funnelData.map((item, index) => {
          if (index !== 0) {
            if (item?.offer_number === offerNumber) {
              return {
                ...item,
                is_active: item.offer_name === offerName,
              };
            }
          }
          return item;
        });
        dispatch(updatesetUpFunnel({ offer_number: offerNumber, offer_name: offerName, updatedFields: updatedFunnelData, disFrom: 'acceptedBtn' }));
      }
    }

    if (btnName === "AddAnotherBtn") {
      const flag = funnelData?.every(item => item?.offer_number < 3);
      if (flag) {
        funnelData.map((item) => {
          const setupOfffersData = {
            offer_name: null,
            offer_number: item.offer_number + 1,
            select_store_product: false,
            create_virtual_product: false,
            product_id: [],
            title: "",
            show_offer_description: false,
            description: "",
            maximum_limit: false,
            maximum_quantity_limit: 1,
            minimum_limit: false,
            minimum_quantity_limit: 1,
            exclude_variants: false,
            include_free_shipping: false,
            exclude_product_variants_list: [],
            language: null,
            show_different_language: false,
            hasDownsellCondition: false,
            is_active: true
          };

          dispatch(updatesetUpFunnel({ offer_number: offerNumber, offer_name: offerName, updatedFields: [...funnelData, setupOfffersData] }))
          return null;
        })

      }
    }
  }

  return (
    <>
      {funnelData?.length > 0 &&
        funnelData?.map((item, index) => {
          return (
            item.is_active && (
              <div key={index} className="add_downsell_main_wrap">
                {
                  (index !== 0 && item?.offer_name) && (
                    <div className="add_downsellbtn">

                      <ButtonGroup segmented>
                        <Button primary={item?.is_active && item?.offer_name === "A"} onClick={() => addDataHandler("acceptedBtn", item?.offer_number, "A")}>If offer #{item.offer_number} was accepted</Button>
                        <Button primary={item?.is_active && item?.offer_name === "B"} onClick={() => addDataHandler("acceptedBtn", item?.offer_number, "B")}>If offer #{item.offer_number} was declined</Button>
                      </ButtonGroup>
                      <div>
                        <img
                          src={iconjoin}
                          alt="icon"
                          className="d-block mx-auto"
                        />
                      </div>
                    </div>
                  )
                }
                <div className="box_wrap pb-5">
                  <HorizontalStack align="space-between">
                    <Text variant="headingMd" as="h5" fontWeight="semibold">
                      Offer #{item?.offer_number}  {item?.offer_name && (`| ${item?.offer_name}`)}
                    </Text>
                    {index !== 0 && (
                      <Button plain onClick={() => deleteoffer(item?.offer_number, item?.offer_name)}>
                        <Icon source={DeleteMajor} color="base" />
                      </Button>
                    )}
                  </HorizontalStack>

                  <Divider />

                  <SelectProduct item={item} index={index} setupHandleChange={setupHandleChange} funnelData={funnelData} />

                  <Divider />

                  {/* Offer text */}
                  <OfferText
                    item={item}
                    funnelData={funnelData}
                  />

                  <Divider />

                  {/* Advanced  */}
                  <Advanced
                    item={item}
                    handleAddField={handleAddField}
                    handleDeleteField={handleDeleteField}
                    addField={addField}
                    funnelData={funnelData}
                  />
                </div>
                {/* accepted declined buttons */}
                {
                  ((page === "post_purchase_page" && item.offer_number < 2) || (page !== "post_purchase_page" && item.offer_number < 3))&& (
                    <div className="add_downsellbtn">
                      {
                        !item?.hasDownsellCondition && (
                          <>
                            <Button onClick={() => addDataHandler("addDownShell", item?.offer_number, item?.offer_name)}>
                              <HorizontalStack blockAlign="center" gap="2">
                                <img src={iconsrc} alt="icon" className="mx-20" /> Add downsell condition
                              </HorizontalStack>
                            </Button>
                            <div>
                              <img
                                src={iconjoin}
                                alt="icon"
                                className="d-block mx-auto"
                              />
                            </div>
                          </>
                        )
                      }
                    </div>
                  )
                }
              </div>
            )
          );
        })
      }
      {
        ((page === "post_purchase_page" && funnelData?.every(item => item?.offer_number < 2)) || (page !== "post_purchase_page" && funnelData?.every(item => item?.offer_number < 3))) &&
        <>
          <div className="add_another">
            <Button primary onClick={() => { addDataHandler("AddAnotherBtn") }}>
              <HorizontalStack blockAlign="center" gap="2">
                <Icon source={CirclePlusMinor} color="base" /> Add another offer
              </HorizontalStack>
            </Button>
          </div>
        </>
      }

    </>
  );
}


// item.offer_number < 3
// funnelData?.every(item => item?.offer_number < 3)
