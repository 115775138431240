/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { Checkbox, HorizontalGrid, Icon, RadioButton, Tag, Text, TextField, VerticalStack, Tooltip } from "@shopify/polaris";
import React, { useCallback, useEffect, useState } from "react";
import { QuestionMarkInverseMinor } from "@shopify/polaris-icons";
import MyResourcePicker from "../../../../Common/MyResourcePicker";
import { useDispatch, useSelector } from "react-redux";
import { FunnelRulesProductList, FunnelRulescollections, ProductList, collectionsList, defaultUpsellData, deleteProduct, deletecollections, deletevariantslist, updateSelectedFunnel, upsellproductdetail } from "../../../../Redux/features/NewFunnel/newfunnelSlice";
import ProductModal from "../Product_Modal/ProductModal";
import { setVariantUpsell } from "../../../../Redux/features/NewFunnel/upsellTabsSlice";
import { variantslist } from "../../../../Redux/features/NewFunnel/newfunnelSlice";

export default function Trigger() {

  //resource picker---------------------------
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedUpsellProducts, setSelectedUpsellProducts] = useState([]);
  const [openResourcePicker, setOpenResourcePicker] = useState(false);
  const hideResourcePicker = () => setOpenResourcePicker(false);
  const showResourcePicker = () => setOpenResourcePicker(true);

  let queue = []
  // redux
  const selectedIndex$ = 1;
  const Trigger$ = useSelector((state) => state?.newfunnel?.data);
  const productlist = Trigger$[selectedIndex$].products_list;
  const variantList = Trigger$[selectedIndex$].excluded_variants_list;
  const Trigger = Trigger$?.[selectedIndex$ - 1]?.offer_type;
  const dispatch = useDispatch();

  // Variants resource picker---------------------------
  const [openVariantsPicker, setOpenVariantsPicker] = useState(false);
  const [variants, setVariants] = useState([]);

  useEffect(() => {
    if (variantList?.length > 0 && variants?.length > 0) {
      const filteredVariants = variantList.filter(product => {
        return variants.some(variant => variant.id === product.id);
      });
      dispatch(variantslist(filteredVariants));
    }
  }, [variants])

  //trigger value
  const radioData = {
    show_for_specific_product: false,
    show_for_all: false,
    show_for_specific_collection: false,
  };
  const handletriggerChange = useCallback(
    (value, name) => {
      dispatch(updateSelectedFunnel({ ...radioData, [name]: value }));
    },
    [Trigger$]
  );

  //exclude variants of product
  const handleVariants = useCallback((value, name) => {
    const updatedFields = {
      [name]: value,
    };
    dispatch(updateSelectedFunnel(updatedFields));
  }, [Trigger$]);

  useEffect(() => {

    const filterproductlist = Trigger$[selectedIndex$]?.do_not_show_for_specific_products_list?.filter((item) => {
      if (Trigger$[selectedIndex$]?.show_for_specific_product) {
        return !Trigger$[selectedIndex$]?.products_list?.some((product) => product?.id === item?.admin_graphql_api_id);
      } else {
        return Trigger$[selectedIndex$]?.do_not_show_for_specific_products_list
      }
    });
    if (filterproductlist) {
      dispatch(FunnelRulesProductList(filterproductlist))
      // variants...
      Trigger$[1]?.products_list?.map((item) => {
        queue.push(...item?.variants)
        setVariants(queue);
      })
    }

  }, [selectedProducts]);
  useEffect(() => {
    if (Trigger$[selectedIndex$]?.upsell_product_detail) {
      setSelectedUpsellProducts([Trigger$[selectedIndex$]?.upsell_product_detail])
    }
  }, [Trigger$[selectedIndex$]?.upsell_product_detail])

  const listSelectedproductTags =
    (Trigger$[selectedIndex$]?.products_list?.length > 0) ? (
      <div>
        {Trigger$[selectedIndex$]?.products_list?.map((product) => (
          <Tag
            onRemove={() =>
              handleRemoveProduct(
                product.id,
                dispatch(deleteProduct(product.id))
              )
            }
            key={product.id}
          >
            <div>
              <span>{product.title}</span>
            </div>
          </Tag>
        ))}
      </div>
    ) : null;

  const upsellSelectedproductTag =
    <>
      {Trigger$[selectedIndex$]?.upsell_product_detail ? (
        <Tag
          onRemove={(e) => {
            e.stopPropagation();
            dispatch(upsellproductdetail(null))
            setSelectedUpsellProducts([])
            const newUpdatedData = Trigger$[2]?.offer?.map(item => ({ ...item, variant_upsell: [], variant_trigger: [] }))
            dispatch(defaultUpsellData(newUpdatedData));
          }
          }
          key={Trigger$[selectedIndex$]?.upsell_product_detail.id}
        >
          <div>
            <span>{Trigger$[selectedIndex$]?.upsell_product_detail.title}</span>
          </div>
        </Tag>
      ) : null
      }
    </>


  // remove products
  const handleRemoveProduct = useCallback((productId) => {
    const updatedProducts = selectedProducts.filter(
      (product) => product.id !== productId
    );

    updatedProducts?.map((item) => {
      queue.push(...item?.variants);
      setVariants(queue);
    })

    if (updatedProducts?.length === 0) {
      const updatedFields = {
        exclude_variants: false,
      };
      dispatch(updateSelectedFunnel(updatedFields));
    }

    setSelectedProducts(updatedProducts);
  }, [variants, Trigger$]);


  // Cancle event of variant modal
  const cancleVariant = useCallback(
    () => setOpenVariantsPicker(!openVariantsPicker),
    [openVariantsPicker]);

  const listSelectedvarinatTags =
    Trigger$[selectedIndex$]?.excluded_variants_list?.length >
      0 ? (
      <div>
        {Trigger$[selectedIndex$]?.excluded_variants_list?.map(
          (product) => (
            <Tag
              onRemove={() =>
                handleRemoveProduct(
                  product.id,
                  dispatch(deletevariantslist(product.id))
                )
              }
              key={product.id}
            >
              <div>
                <span>{product.title}</span>
              </div>
            </Tag>
          )
        )}
      </div>
    ) : null;

  // Collections resource picker---------------------------
  const [selectedCollections, setSelectedCollections] = useState([]);
  const [openCollectionsPicker, setOpenCollectionsPicker] = useState(false);

  const hidecollectionPicker = () => setOpenCollectionsPicker(false);
  const showcollectionPicker = () => setOpenCollectionsPicker(true);

  const handleSelectcollection = async ({ selection }) => {
    setSelectedCollections([...selection]);
    const productId = [];
    selection?.map((product) => (
      productId.push(product)
    ));

    dispatch(collectionsList(productId));
    hidecollectionPicker();
  };

  const handleSelectProduct = useCallback(({ selection }) => {
    if (Trigger$[0].offer_type === "upsell") {
      setSelectedUpsellProducts([...selection]);
      dispatch(upsellproductdetail(...selection));
      hideResourcePicker();
      const filterproductlist = Trigger$[selectedIndex$]?.do_not_show_for_specific_products_list?.filter((item) => {
        if (selection?.length > 0) {
          return !selection?.some((product) => product?.id === item?.admin_graphql_api_id);
        } else {
          return Trigger$[selectedIndex$]?.do_not_show_for_specific_products_list
        }
      });
      dispatch(FunnelRulesProductList(filterproductlist))

      if (Trigger$[1]?.upsell_product_detail?.id !== selection[0]?.id) {
        const newData = {
          offer_number: 1,
          variant_trigger: [],
          variant_upsell: [],
          offer_text: "",
          show_different_language: false,
          language: [],
          show_offer_description: false,
          description: "",
          include_free_shipping: false,
        }
        dispatch(defaultUpsellData([newData]));
        dispatch(setVariantUpsell([newData]))
      }
    } else {
      setSelectedProducts([...selection]);
      const productId = [];
      selection?.map((product) => (
        productId.push(product)
      ));
      dispatch(ProductList(productId));
      hideResourcePicker();
    }

  }, [selectedProducts, Trigger$, variants, selectedUpsellProducts]);


  useEffect(() => {
    setSelectedCollections(Trigger$[selectedIndex$]?.collections_list);
    setSelectedProducts(productlist);
  }, [selectedCollections, selectedProducts]);

  useEffect(() => {
    const filtercollectiontlist = Trigger$[selectedIndex$]?.do_not_show_for_specific_collections_list?.filter((item) => {
      if (Trigger$[selectedIndex$]?.show_for_specific_collection) {
        return !Trigger$[selectedIndex$]?.collections_list?.some((product) => product?.id === item?.admin_graphql_api_id);
      } else {
        return Trigger$[selectedIndex$]?.do_not_show_for_specific_collections_list
      }
    });
    if (filtercollectiontlist) {
      dispatch(FunnelRulescollections(filtercollectiontlist))
    }

  }, [selectedCollections])

  const listSelectedCollections =
    Trigger$[selectedIndex$]?.collections_list?.length > 0 ? (
      <div>
        {Trigger$[selectedIndex$]?.collections_list?.map((collection) => (
          <Tag
            onRemove={() =>
              handleRemovecollection(
                collection.id,
                dispatch(deletecollections(collection.id))
              )
            }
            key={collection.id}
          >
            <div>
              <span>{collection.title}</span>
            </div>
          </Tag>
        ))}
      </div>
    ) : null;

  const handleRemovecollection = (collectionId) => {
    const updatedCollections = selectedCollections.filter(
      (collection) => collection.id !== collectionId
    );
    setSelectedCollections(updatedCollections);
  };

  return (
    <div>
      <div className="box_wrap trigger_step">
        <HorizontalGrid gap="4" columns={{ md: 2 }}>

          <VerticalStack>
            <Text variant="headingMd" as="h5">
              Trigger
            </Text>
            <p>Your funnel will be triggered based on these rules.</p>
          </VerticalStack>

          {Trigger === "upsell" ? (
            <div className="mt-2 product_select">
              <TextField
                value=""
                onFocus={() => {
                  setOpenResourcePicker(true);
                }}
                placeholder="Select a Product"
                verticalContent={upsellSelectedproductTag}
                labelAction={{
                  content: (
                    <Icon source={QuestionMarkInverseMinor} color="base" />
                  ),
                }}
                autoComplete="off"
              />
              <MyResourcePicker
                resourceType={"Product"}
                openResourcePicker={openResourcePicker}
                hideResourcePicker={hideResourcePicker}
                showResourcePicker={showResourcePicker}
                handleSelectProduct={handleSelectProduct}
                selectedItems={selectedUpsellProducts}
                selectMultiple={false}
              />
            </div>
          ) : (
            <VerticalStack>
              <RadioButton
                label={
                  <>
                    <div className="d-flex">
                      {
                        Trigger$[0]?.funnel_page === "cart_page" ? 'Show when any product is present in cart ' : 'Show when any product is added to cart '
                      }
                      <span className="ps-1">
                        <Tooltip content="Select this option if you want to show this funnel for all buyers">
                          <Icon source={QuestionMarkInverseMinor} color="base" />
                        </Tooltip>
                      </span>
                    </div>
                  </>
                }
                checked={Trigger$[selectedIndex$]?.show_for_all === true}
                id="show_for_all"
                name="trigger"
                onChange={(value) => {
                  handletriggerChange(value, "show_for_all");
                }}
              />

              <div className="my-4 ">
                <RadioButton
                  label={
                    <>
                      <div className="d-flex">
                        {
                          Trigger$[0]?.funnel_page === "cart_page" ? 'Show only when specific products are present in cart ' : 'Show only when specific products are added to cart '
                        }
                        <span className="ps-1">
                          <Tooltip content="Buyers would see this funnel only if they are in these products pages">
                            <Icon source={QuestionMarkInverseMinor} color="base" />
                          </Tooltip>
                        </span>
                      </div>
                    </>
                  }
                  id="show_for_specific_product"
                  name="trigger"
                  checked={Trigger$[1]?.show_for_specific_product === true}
                  onChange={(value) => {
                    handletriggerChange(value, "show_for_specific_product");
                  }}
                />
                {Trigger$[1]?.show_for_specific_product && (
                  <div className="mt-2 product_select">

                    {/* select product field */}
                    <div
                      onClick={() => {
                        setOpenResourcePicker(true);
                      }}
                    >
                      <TextField
                        value=""
                        placeholder="Select Products"
                        verticalContent={listSelectedproductTags}
                        labelAction={{
                          content: (
                            <Icon
                              source={QuestionMarkInverseMinor}
                              color="base"
                            />
                          ),
                        }}
                        autoComplete="off"
                      />
                    </div>

                    {/* select product resource picker */}
                    <MyResourcePicker
                      resourceType={"Product"}
                      openResourcePicker={openResourcePicker}
                      hideResourcePicker={hideResourcePicker}
                      showResourcePicker={showResourcePicker}
                      handleSelectProduct={handleSelectProduct}
                      selectedItems={selectedProducts}
                    />

                    {/* Exclude variants */}
                    {Trigger$[selectedIndex$]?.products_list?.length > 0 && (
                      <div className="mt-2">

                        {/* Exclude variants checkbox */}
                        <Checkbox
                          label={
                            <div className="d-flex">
                              Exclude variants
                              <span className="ps-1">
                                <Tooltip content="Check this box if you want to exclude some variants from this offer.">
                                  <Icon source={QuestionMarkInverseMinor} color="base" />
                                </Tooltip>
                              </span>
                            </div>
                          }
                          checked={Trigger$[selectedIndex$]?.exclude_variants}
                          onChange={(value) => handleVariants(value, "exclude_variants")}
                        />

                        {Trigger$[selectedIndex$]?.exclude_variants && (
                          <div className="mt-2 product_select">

                            {/* Select variants */}
                            <div
                              onClick={() => {
                                setOpenVariantsPicker(true);
                              }}>
                              <TextField
                                value=""
                                placeholder="Select variants"
                                verticalContent={listSelectedvarinatTags}
                                labelAction={{
                                  content: (
                                    <Icon
                                      source={QuestionMarkInverseMinor}
                                      color="base"
                                    />
                                  ),
                                }}
                                autoComplete="off"
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    )}

                  </div>
                )}
              </div>
              <RadioButton
                label={
                  <>
                    {
                      Trigger$[0]?.funnel_page === "cart_page" ? 'Show only when products from specific collections are present in cart ' : 'Show only when products from specific collections are added to cart '
                    }
                  </>
                }
                id="show_for_specific_collection"
                name="trigger"
                checked={Trigger$[selectedIndex$]?.show_for_specific_collection === true}
                onChange={(value) => {
                  handletriggerChange(value, "show_for_specific_collection");
                }}
              />

              {/* select collection */}
              {Trigger$[selectedIndex$]?.show_for_specific_collection && (
                <div
                  className="mt-2 product_select"
                  onClick={() => {
                    setOpenCollectionsPicker(true);
                  }}
                >
                  <MyResourcePicker
                    resourceType={"Collection"}
                    openResourcePicker={openCollectionsPicker}
                    hideResourcePicker={hidecollectionPicker}
                    showResourcePicker={showcollectionPicker}
                    handleSelectProduct={handleSelectcollection}
                    selectedItems={selectedCollections}
                  />
                  <TextField
                    value=""
                    verticalContent={listSelectedCollections}
                    placeholder="Select collections"
                    labelAction={{
                      content: (
                        <Icon source={QuestionMarkInverseMinor} color="base" />
                      ),
                    }}
                    autoComplete="off"
                  />
                </div>
              )}
            </VerticalStack>
          )}
        </HorizontalGrid>
      </div>

      {/* Select variants resource picker */}
      {
        openVariantsPicker &&
        <ProductModal modalName={'Variants'} open={openVariantsPicker} cancle={cancleVariant} data={variants} />
      }
    </div>
  );
}
