import { Toast, Frame } from '@shopify/polaris';
import React from 'react';

const ToastCommon = ({ status, msg, setToastStatus, error, time }) => {
    const closeToast = () => {
        setToastStatus(false)
    };

    const toastMarkup = status ? (
        <Toast content={msg} error={error} onDismiss={closeToast} duration={time} />
    ) : null;


    return (
        <div className='toaster_main_wrap'>
            <Frame>
                {toastMarkup}
            </Frame>
        </div>
    );
};

export default ToastCommon;
