import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    variant_upsell: [
        {
            // offer_name: null,
            offer_number: 1,
            variant_trigger: [],
            variant_upsell: [],
            offer_text: "",
            show_different_language: false,
            language: [],
            show_offer_description: false,
            description: "",
            include_free_shipping: false,
        },
    ],
    product_upsell: [
        {
            // offer_name: null,
            offer_number: 1,
            upsell_product: [],
            offer_text: "",
            language: [],
            show_different_language: false,
            show_offer_description: false,
            description: "",
            include_free_shipping: false,
            exclude_variants: false,
            exclude_product_variants_list: [],
        },
    ],
    crosell: [
        {
            offer_name: null,
            offer_number: 1,
            select_store_product: false,
            create_virtual_product: false,
            product_id: [],
            title: "",
            show_offer_description: false,
            description: "",
            maximum_limit: false,
            maximum_quantity_limit: 1,
            minimum_limit: false,
            minimum_quantity_limit: 1,
            exclude_variants: false,
            include_free_shipping: false,
            exclude_product_variants_list: [],
            language: [],
            show_different_language: false,
            hasDownsellCondition: false,
            is_active: true
        }
    ],
    productData: [],
    collectionData: [],
};

export const upsellTabsSlice = createSlice({
    name: "upsellTabs",
    initialState,
    reducers: {
        setVariantUpsell: (state, action) => {
            state.variant_upsell = action.payload;
        },
        setProductUpsell: (state, action) => {
            state.product_upsell = action.payload;
        },
        setcrosell: (state, action) => {
            state.crosell = action.payload;
        },
        setProductData: (state, action) => {
            state.productData = action.payload;
        },
        setCollectionData: (state, action) => {
            state.collectionData = action.payload;
        },
    }
});

export const {
    setVariantUpsell,
    setProductUpsell,
    setcrosell,
    setProductData,
    setCollectionData
} = upsellTabsSlice.actions;

export default upsellTabsSlice.reducer;
