import { HorizontalStack, Modal, Spinner } from '@shopify/polaris';

export default function ModalCommon({ width, title, open, cancle = '', confirmation, cancleHandleChange, body, confirmHandle, destructive = false, loading, disableConfirmation }) {
    const cancleAction = () => {
        if (!loading) {
            cancleHandleChange();
        }
    }

    const deleteAction = () => {
        confirmHandle();
    }
    return (
        <Modal
            small={width}
            open={open}
            onClose={loading ? undefined : cancleHandleChange}
            title={title}
            primaryAction={{
                content: loading ? (<HorizontalStack blockAlign="center" align="center" className="my-2">
                    <Spinner size="small" />
                </HorizontalStack>) : `${confirmation}`,
                onAction: deleteAction,
                destructive: destructive,
                disabled: disableConfirmation
            }}
            secondaryActions={
                !cancle ? [] : [
                    {
                        content: `${cancle}`,
                        onAction: cancleAction,
                    },
                ]}
        >
            <Modal.Section>
                {body}
            </Modal.Section>
        </Modal>
    )
}
