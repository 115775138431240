import "@shopify/polaris/build/esm/styles.css";
import '@shopify/polaris-viz/build/esm/styles.css';
import "./Assets/css/main.scss";
import { AppProvider } from "@shopify/polaris";
import enTranslations from "@shopify/polaris/locales/en.json";
import { Route, Routes } from "react-router-dom";
import Funnel from "./Components/Funnel/Funnel_List/Funnel";
import NewFunnel from "./Components/Funnel/Funnel_Create/NewFunnel";
import { Provider, NavigationMenu } from "@shopify/app-bridge-react";
// import { useLocation } from "react-router";
import { SHOPIFY_API_KEY } from "./config";
import Dashboard from "./Components/Dashboard/Dashboard";
import Login from "./Components/Login";

function App() {
  // const location = useLocation();

  const urlSearchParams = new URLSearchParams(window.location.search);
  const host =
    urlSearchParams.get("host") ||
    "YWRtaW4uc2hvcGlmeS5jb20vc3RvcmUva2VzaGF2LWRlbW8tc3RvcmU";

  const config = {
    apiKey: SHOPIFY_API_KEY,
    host,
    forceRedirect: true,
  };

  // const config = {
  //   apiKey: SHOPIFY_API_KEY,
  //   // host: "YWRtaW4uc2hvcGlmeS5jb20vc3RvcmUva2VzaGF2LWRlbW8tc3RvcmU",
  //   host: new URLSearchParams(location.search).get("host","YWRtaW4uc2hvcGlmeS5jb20vc3RvcmUva2VzaGF2LWRlbW8tc3RvcmU"),
  //   forceRedirect: true,
  // };
  return (
    <AppProvider i18n={enTranslations}>
      <Provider config={config}>
        <Routes>
          <Route exact path="/funnels" title="Home" element={<Funnel />} />
          <Route exact path="/dashboard" title="Funnel Growth" element={<Dashboard   />} />
          <Route exact path="/funnel/:id/analytics" title="Analytics" element={<Dashboard />} />
          <Route
            exact
            path="/funnels/new"
            title="Home"
            element={<NewFunnel />}
          />
          <Route
            exact
            path="/funnel/:id/edit"
            title="Home"
            element={<NewFunnel />}
          />
          <Route
            exact
            path="/funnel/:id/duplicate"
            title="Home"
            element={<NewFunnel />}
          />
          <Route exact path="/login" title="login" element={<Login />} />
        </Routes>
        <NavigationMenu
          navigationLinks={[
            {
              label: "Funnels",
              destination: "/funnels",
            },
            {
              label: "Funnels Growth",
              destination: "/dashboard",
            },
            // {
            //   label: "New Funnel",
            //   destination: "/funnels/new",
            // },
          ]}
          matcher={(link, location) => link.destination === location.pathname}
        />
      </Provider>
    </AppProvider>
  );
}

export default App;
