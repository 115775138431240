/* eslint-disable react-hooks/exhaustive-deps */
import {
  Badge,
  Divider,
  Grid,
  HorizontalGrid,
  HorizontalStack,
  Icon,
  Link,
  RadioButton,
  Text,
  TextField,
  VerticalStack,
} from "@shopify/polaris";
import React, { useCallback, useEffect } from "react";
import { ExternalMinor } from "@shopify/polaris-icons";
import src from "../../../../Assets/images/icons/checkout-shopiy.svg";
import Cartpage from "../../../../Assets/images/icons/cartpage.svg";
import Purchasepage from "../../../../Assets/images/icons/purchasepage.svg";
import Checkoutpage from "../../../../Assets/images/icons/checkoutpage.svg";
import Thankyoupage from "../../../../Assets/images/icons/thankyoupage.svg";
import producticon from "../../../../Assets/images/icons/iconpage.svg";
import { useDispatch, useSelector } from "react-redux";
import { defaultUpsellData, updateSelectedFunnel } from "../../../../Redux/features/NewFunnel/newfunnelSlice";
import { setcrosell } from "../../../../Redux/features/NewFunnel/upsellTabsSlice";

export default function CreateFunnel() {
  // textfield value
  const selectedIndex$ = useSelector((state) => state?.newfunnel?.seleted_step);
  const newFunnel$ = useSelector((state) => state?.newfunnel?.data);
  const dispatch = useDispatch();
  const handleChange = useCallback(
    (newValue, name) => {

      const updatedFields = {
        [name]: newValue?.trimStart()
      };
      dispatch(updateSelectedFunnel(updatedFields));
    }, [newFunnel$]);


  useEffect(() => {
    if (newFunnel$[0]?.funnel_page === "checkout_page" || newFunnel$[0]?.funnel_page === "post_purchase_page" || newFunnel$[0]?.funnel_page === "thank_you_page") {
      const updatedFields = {
        offer_type: "cross_sell",
      };
      dispatch(updateSelectedFunnel(updatedFields));
    }
    if(newFunnel$[0]?.funnel_page === "post_purchase_page"){
      const filterData = newFunnel$[2]?.offer?.filter(data => data?.offer_number < 3)
      dispatch(defaultUpsellData(filterData));
      dispatch(setcrosell([filterData]));
    }
    if(newFunnel$[0]?.funnel_page !== "post_purchase_page" && newFunnel$[2]?.offer?.length <= 3){
      const newData = newFunnel$[2]?.offer?.map(item => {
        if (item.offer_number === 2) {
          return {
            ...item,
            hasDownsellCondition: false // Set the property directly without spreading
          };
        }
        return item;
      });
      dispatch(defaultUpsellData(newData));
      dispatch(setcrosell([newData]));
    }
  }, [newFunnel$[0]?.funnel_page])


  // select page
  const handleRadioChange = useCallback(
    (newValue, name) => {
      const updatedFields =
      {
        [name]: newValue,
        follow_up_action: newValue === "product_page" ? newFunnel$[0]?.follow_up_action === '' ? 'checkout_page' : newFunnel$[0]?.follow_up_action : '',
        offer_type: newValue === "product_page" || newValue === "cart_page" ? newFunnel$[0]?.offer_type === '' ? 'cross_sell' : newFunnel$[0]?.offer_type : '',
      };
      dispatch(updateSelectedFunnel(updatedFields));
    }, [newFunnel$]);

  // select offer & follow type
  const handleOfferRadioChange = useCallback(
    (newValue, name) => {
      const updatedFields =
      {
        [name]: newValue
      };
      dispatch(updateSelectedFunnel(updatedFields));
    }, [newFunnel$]);

  const showFunnelPage = [
    {
      id: 1,
      radioLabel: "Product Page",
      iconPagesrc: producticon,
      radioValue: "product_page",
    },
    {
      id: 2,
      radioLabel: "Cart Page",
      iconPagesrc: Cartpage,
      radioValue: "cart_page",
    },
    {
      id: 3,
      checkout: true,
      radioLabel: "Checkout Page",
      iconPagesrc: Checkoutpage,
      radioValue: "checkout_page",
    },
    {
      id: 4,
      badge: "Recommended",
      radioLabel: "Post Purchase",
      iconPagesrc: Purchasepage,
      radioValue: "post_purchase_page",
    },
    {
      id: 5,
      radioLabel: "Thank You Page",
      iconPagesrc: Thankyoupage,
      radioValue: "thank_you_page",
    },
  ];

  return (
    <div className="step_content box_wrap">
      <Grid>
        <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 8, lg: 8, xl: 8 }}>
          <Text variant="headingMd" as="h5">
            Funnel Name
          </Text>
          <p>This name will not be displayed to your buyer.</p>
        </Grid.Cell>
        <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 4, lg: 4, xl: 4 }}>
          <div className="select_main">
            <TextField
              placeholder="Enter Funnel Name"
              value={newFunnel$[selectedIndex$]?.funnel_name}
              onChange={(value) => handleChange(value, "funnel_name")}
              autoComplete="off"
            />
          </div>
        </Grid.Cell>
      </Grid>
      <Divider />

      <div className="select_container mb-4">
        <Text variant="headingMd" as="h5">
          Where would you like to sell more?
        </Text>
        <HorizontalStack gap="1">
          <Text variant="headingSm" as="p">
            Select a page to show this funnel in. Need help?{" "}
          </Text>
          <Link>
            <HorizontalStack blockAlign="center">
              Learn When each fennel shows.
              <Icon source={ExternalMinor} color="base" />
            </HorizontalStack>
          </Link>
        </HorizontalStack>
      </div>

      {/* select funnel page */}
      <HorizontalGrid gap="2" columns={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }}>
        {showFunnelPage.map((item) => {
          return (
            <div
              className={`${newFunnel$[selectedIndex$]?.funnel_page === item.radioValue
                ? "checked"
                : ""
                } radio_box`}
              key={item.id}
            >
              {item.checkout === true && (
                <img src={src} className="icon_left m-0" alt="icon" />
              )}

              {item.badge != null && <Badge>{item.badge}</Badge>}

              <img src={item.iconPagesrc} alt="icon" />
              <RadioButton
                label={item.radioLabel}
                checked={
                  newFunnel$[selectedIndex$]?.funnel_page === item.radioValue
                }
                name="page"
                id={item.id}
                value={newFunnel$[selectedIndex$]?.funnel_page}
                onChange={() =>
                  handleRadioChange(item.radioValue, "funnel_page")
                }
              />
            </div>
          );
        })}
      </HorizontalGrid>

      {/* select offer */}
      {(newFunnel$[selectedIndex$]?.funnel_page === "product_page" ||
        newFunnel$[selectedIndex$]?.funnel_page === "cart_page") && (
          <>
            <Divider />

            <HorizontalGrid gap="4" columns={{ md: 2 }}>
              <VerticalStack>
                <Text variant="headingMd" as="h5">
                  Select offer type
                </Text>
                <p>Your funnel will be triggered based on these rules.</p>
              </VerticalStack>
              <VerticalStack>
                <RadioButton
                  label="Cross Sell"
                  helpText="Add complimentary products or add more of the same product."
                  checked={
                    newFunnel$[selectedIndex$]?.offer_type === "cross_sell"
                  }
                  id="disabled"
                  name="accounts"
                  value={newFunnel$[selectedIndex$]?.offer_type}
                  onChange={() => handleOfferRadioChange("cross_sell", "offer_type")}
                />
                <RadioButton
                  label="Upsell"
                  helpText="Replace an existing product or a product variant with another."
                  id="optional"
                  name="accounts"
                  value={newFunnel$[selectedIndex$]?.offer_type}
                  checked={newFunnel$[selectedIndex$]?.offer_type === "upsell"}
                  onChange={() => handleOfferRadioChange("upsell", "offer_type")}
                />
              </VerticalStack>
            </HorizontalGrid>
          </>
        )}

      {/* followe up action */}
      {newFunnel$[selectedIndex$]?.funnel_page === "product_page" && (
        <>
          <Divider />
          <HorizontalGrid gap="4" columns={{ md: 2 }}>
            <VerticalStack>
              <Text variant="headingMd" as="h5">
                Follow up action
              </Text>
              <p>When an offer is accepted:</p>
            </VerticalStack>
            <VerticalStack>
              <RadioButton
                label="Go to checkout"
                checked={
                  newFunnel$[selectedIndex$]?.follow_up_action ===
                  "checkout_page"
                }
                id="checkout"
                name="actions"
                value={newFunnel$[selectedIndex$]?.follow_up_action}
                onChange={() =>
                  handleOfferRadioChange("checkout_page", "follow_up_action")
                }
              />
              <div className="my-4">
                <RadioButton
                  label="Go to cart page"
                  helpText="Please note: when selecting this option, you won’t be able to add discounts to your offers."
                  id="cart"
                  name="actions"
                  checked={
                    newFunnel$[selectedIndex$]?.follow_up_action === "cart_page"
                  }
                  value={newFunnel$[selectedIndex$]?.follow_up_action}
                  onChange={() =>
                    handleOfferRadioChange("cart_page", "follow_up_action")
                  }
                />
              </div>
              <RadioButton
                label="Stay on page"
                id="stay"
                name="actions"
                checked={
                  newFunnel$[selectedIndex$]?.follow_up_action === "same_page"
                }
                value={newFunnel$[selectedIndex$]?.follow_up_action}
                onChange={() =>
                  handleOfferRadioChange("same_page", "follow_up_action")
                }
              />
            </VerticalStack>
          </HorizontalGrid>
        </>
      )}
    </div>
  );
}
