/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  ButtonGroup,
  HorizontalStack,
  Icon,
  Page,
  Spinner,
  Text,
} from "@shopify/polaris";
import React, { useEffect, useState } from "react";
import { TickSmallMinor } from "@shopify/polaris-icons";
import { useDispatch, useSelector } from "react-redux";
import axios from "../../../Helper/axios";
import { editFunnel, selectedStep, setSelectedTab } from "../../../Redux/features/NewFunnel/newfunnelSlice";
import { SERVER_BASE_URL } from "../../../config";
import CreateFunnel from "./Step_One/CreateFunnel";
import FunnelRules from "./Step_Two/FunnelRules";
import SetupOffersmain from "./Step_Three/SetupOffersmain";
import ModalCommon from "../../../Common/ModalCommon";
import { useNavigate } from "@shopify/app-bridge-react"
import { useParams } from "react-router-dom";
import SetupUpsellOfferMain from "./Step_Three/SetupUpsellOfferMain";
import { setProductUpsell, setVariantUpsell, setcrosell } from "../../../Redux/features/NewFunnel/upsellTabsSlice";


export default function NewFunnel() {
  const params = useParams();
  const funnelData = useSelector((state) => state?.newfunnel?.data);
  // const upsellTabData = useSelector((state) => state?.upsellTabs);
  const funneloffertype = useSelector((state) => state?.newfunnel)
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  // const [resFunnelId, setResFunnelId] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  // Fetch from localStorage - FFLS
  const [FFLS, setFFLS] = useState(true);
  const [isDraft, setIsDraft] = useState(false);
  const [success, setSuccess] = useState(null)
  const [isEdit, setIsEdit] = useState(false);
  const [loader, setLoader] = useState(false);
  const [msgModal, setMsgModal] = useState(false);
  const [drafModal, setDrafModal] = useState(false);
  const [text, setText] = useState("");

  const confirmMsgmodal = () => {
    setStep(step)
    setMsgModal(false)
  }
  const confirmDrafmodal = () => {
    setDrafModal(false);

  }
  const openMsgModal = () => {
    setStep(1)
    setMsgModal(false)
  }
  const openDrafModal = () => {
    localStorage.clear()
    window.location.reload();
    setDrafModal(false)
  }


  useEffect(() => {
    if (params?.id) {
      editFunnelHandler();
    } else {
      setFFLS(false)
    }
  }, [params?.id])

  const editFunnelHandler = () => {
    setLoader(true);
    axios({
      method: "get",
      baseURL: SERVER_BASE_URL,
      url: `/api/funnel/${params?.id}`,
    }).then(
      (response) => {
        if (!response?.data?.success) {
          navigate("/funnels")
        }else{
          const defaultCollectionData = {
            show_for_all: false,
            show_for_specific_product: false,
            products_list: [],
            exclude_variants: false,
            excluded_variants_list: [],
            show_for_specific_collection: false,
            collections_list: []
          };
          let data = response?.data?.data
          setIsEdit(true);
          setFFLS(true)
          const formattedStartTime = new Date(data[1].start_time);
          const formattedEndTime = new Date(data[1].end_time);
          data[1].start_time = formattedStartTime;
          data[1].end_time = formattedEndTime;
          if (data[2].offer === null) {
            data[2].offer = funnelData?.[2].offer
          }
          if (!data[1]?.hasOwnProperty('collections_list' || 'show_for_all' || 'show_for_specific_product' || 'products_list' || 'exclude_variants' || 'excluded_variants_list' || 'show_for_specific_collection')) {
            // Add defaultCollectionData to data[1]
            data[1] = { ...data[1], ...defaultCollectionData };
          }
          data[2].offer?.map((value) => {
            if (value.maximum_quantity_limit === null) {
              return value.maximum_quantity_limit = 1
            }
          })
          data[2].offer?.map((value) => {
            if (value.minimum_quantity_limit === null) {
              return value.minimum_quantity_limit = 1
            }
          })
          if (data[0].offer_type === "upsell" && data[3].selectedTab === 0) {
            dispatch(setVariantUpsell(data[2].offer))
          } else if (data[0].offer_type === "upsell" && data[3].selectedTab === 1) {
            dispatch(setProductUpsell(data[2].offer))
          }
          if (data[0].offer_type === "cross_sell") {
            dispatch(setcrosell([data[2].offer]))
          }
          if (window.location.pathname.split('/').at(-1) === "duplicate") {
            data[0].funnel_name = data[0].funnel_name + " (copy)";
            if (data[1].funnel_priority < 10) {
              data[1].funnel_priority += 1
            }
          }
  
          dispatch(setSelectedTab(data[3].selectedTab))
          dispatch(editFunnel(data))
          setLoader(false);
        }
       

        
      },
      (error) => {
      }
    );
  }

  const newFunnelAddHandler = () => {
    if (success && !isEdit) {
      window.location.reload();
      setOpenModal(!openModal);
      navigate("/funnels/new");
      localStorage.clear()
    } else {
      setOpenModal(!openModal);
    }

  }

  const confirmHandle = () => {
    if (success) {
      setOpenModal(!openModal);
      navigate("/funnels");
      localStorage.clear()
    } else {
      setOpenModal(!openModal);
    }

  }

  const handleNext = () => {
    let count = step + 1;
    if (count === 4) {
      submitHandler();
    }
    if (count < 4) {
      setStep(count)
    }
  };

  const handleBack = () => {
    let count = step - 1;
    setStep(count === 1 ? 1 : count);
  }


  const submitHandler = () => {
    let payload;
    payload = funnelData
    let URL;
    let METHOD;
    if (isEdit && params?.id && window.location.pathname.split('/').at(-1) === "edit") {
      URL = `/api/funnel/${params?.id}/`;
      METHOD = "put";
    } else {
      URL = `/api/funnel/`
      METHOD = "post";
    }
    setLoader(true);
    axios({
      method: METHOD,
      baseURL: SERVER_BASE_URL,
      url: URL,
      data: payload
    }).then(
      (response) => {
        setSuccess(response?.data?.success)
        setText(response?.data?.msg);
        setOpenModal(!openModal);
        setLoader(false);
        setIsDraft(false);

      },
      (error) => {
        setOpenModal(!openModal);
        setLoader(false);
        setSuccess(error?.response?.data?.success)
        setText(error?.response?.data?.msg);
      }
    );
  }

  // data post 
  useEffect(() => {
    dispatch(selectedStep(step - 1));
  }, [step]);


  // disabled next button.....
  const [disable, setDisable] = useState(true);
  useEffect(() => {
    if (step === 1) {
      if (
        funnelData[0]?.funnel_name !== "" &&
        funnelData[0]?.funnel_page !== ""
      ) {
        setDisable(false);
      } else {
        setDisable(true);
      }
    }
    if (step === 2) {
      if (
        (funnelData[1]?.show_for_specific_product === true &&
          funnelData[1]?.products_list?.length > 0) ||
        (funnelData[1]?.show_for_specific_collection === true &&
          funnelData[1]?.collections_list?.length > 0) ||
        funnelData[1]?.show_for_all === true ||
        funnelData[1]?.upsell_product_detail

      ) {
        if (
          funnelData[1]?.do_not_show_for_specific_product === false &&
          funnelData[1]?.do_not_show_for_specific_collection === false &&
          funnelData[1]?.target_countries_status === false &&
          funnelData[1]?.exclude_variants === false
        ) {
          setDisable(false);
        } else if (
          funnelData[1]?.target_countries?.length <= 0 &&
          funnelData[1]?.do_not_show_for_specific_collections_list?.length <= 0 &&
          funnelData[1]?.do_not_show_for_specific_products_list?.length <= 0 &&
          funnelData[1]?.excluded_variants_list?.length <= 0
        ) {
          setDisable(true);
        } else {
          setDisable(false);
        }
      } else {
        setDisable(true);
      }

      const conditionArr = [
        {
          con1: 'do_not_show_for_specific_product',
          con2: 'do_not_show_for_specific_products_list'
        },
        {
          con1: 'do_not_show_for_specific_collection',
          con2: 'do_not_show_for_specific_collections_list'
        },
        {
          con1: 'target_countries_status',
          con2: 'target_countries'
        },
        {
          con1: 'exclude_variants',
          con2: 'excluded_variants_list'
        }
      ];

      for (let index = 0; index < conditionArr.length; index++) {
        const item = conditionArr[index];
        if (funnelData[1][item.con1] && funnelData[1][item.con2].length === 0) {
          setDisable(true);
          break;
        }
      }
    }
    if (step === 3) {
      if (funnelData[0].offer_type === "upsell") {
        if (funnelData[1].upsell_product_detail) {
          const disableFlag = funnelData[2]?.offer?.every((value) =>
            (((value.select_store_product === true && value?.product_id?.length > 0) && (value?.title?.length > 0)) || (value?.variant_trigger?.length > 0 && value?.variant_upsell?.length > 0 && value?.offer_text?.length > 0) || (value?.upsell_product?.length > 0 && value?.offer_text?.length > 0))
          );
          setDisable(!disableFlag);
        } else {
          setDisable(true)
        }
      } else {
        const disableFlag = funnelData[2]?.offer?.every((value) =>
          (((value.select_store_product === true && value?.product_id?.length > 0) && (value?.title?.length > 0)) || (value?.variant_trigger?.length > 0 && value?.variant_upsell?.length > 0 && value?.offer_text?.length > 0) || (value?.upsell_product?.length > 0 && value?.offer_text?.length > 0))
        );
        setDisable(!disableFlag);
      }
      funnelData[2]?.offer?.map((value) => {
        const conditionArr = [
          {
            con1: 'exclude_variants',
            con2: 'exclude_product_variants_list'
          },
          {
            con1: 'show_offer_description',
            con2: 'description'
          },
          {
            con1: 'show_different_language',
            con2: 'language'
          }
        ];

        for (let index = 0; index < conditionArr?.length; index++) {
          const item = conditionArr[index];
          if (value[item.con1] && value[item.con2]?.length === 0) {
            setDisable(true);
            break;
          }
        }
      })


    }
  }, [step, disable, funnelData]);

  useEffect(() => {
    if (!FFLS) {
      const localData = localStorage.getItem('funnelData');
      const localSData = JSON.parse(localData)

      if (step >= 1) {
        if (localSData) {
          if (localSData?.data[0]?.funnel_name?.length > 0) {

            let data = localSData?.data
            const formattedStartTime = new Date(data[1].start_time);
            const formattedEndTime = new Date(data[1].end_time);
            data[1].start_time = formattedStartTime;
            data[1].end_time = formattedEndTime;
            if (data[2].offer === null) {
              data[2].offer = funnelData?.[2].offer
            }

            if (data[0].offer_type === "upsell" && localSData?.offer_type === "upsell_variant" && data[2]?.offer?.some((value) => (value?.variant_trigger?.length > 0 || value?.variant_upsell?.length > 0 || value?.offer_text?.length > 0 || value?.language?.length > 0 || value?.description?.length > 0 || value?.show_different_language || value?.show_offer_description || value?.include_free_shipping))) {
              dispatch(setVariantUpsell(data[2].offer))
              dispatch(setSelectedTab(0))
            } else if (data[0].offer_type === "upsell" && localSData?.offer_type === "upsell_product" && data[2]?.offer?.some((value) => value?.upsell_product?.length > 0 || value?.offer_text?.length > 0 || value?.language?.length > 0 || value?.description?.length > 0 || value?.exclude_product_variants_list?.length > 0 || value?.show_different_language || value?.show_offer_description || value?.include_free_shipping || value?.exclude_variants)) {
              dispatch(setProductUpsell(data[2].offer))
              dispatch(setSelectedTab(1))
            }

            if (data[0].offer_type === "cross_sell" && data[2]?.offer?.some((value) => (value?.product_id?.length > 0 || value?.title?.length > 0 || value?.description?.length > 0 || value?.maximum_quantity_limit >= 1 || value?.minimum_quantity_limit >= 1 || value?.exclude_product_variants_list?.length > 0 || value?.language?.length > 0 || value?.select_store_product || value?.create_virtual_product || value?.show_offer_description || value?.maximum_limit || value?.minimum_limit || value?.exclude_variants || value?.include_free_shipping || value?.show_different_language))) {
              dispatch(setcrosell([data[2].offer]))
            }

            setIsDraft(true);
            dispatch(editFunnel(data))
            setDrafModal(true)
          } else {
            localStorage.setItem('funnelData', JSON.stringify(funneloffertype));
          }
        }
      }
    }

  }, [FFLS])


  useEffect(() => {
    if (!FFLS) {
      localStorage.setItem('funnelData', JSON.stringify(funneloffertype));
    }
  }, [funnelData, FFLS])

  return (
    loader ? <>
      <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '20px' }}>
        <Spinner accessibilityLabel="Spinner example" size="large" />
      </div>
    </> :
      <>
        <div className="main_layout_page">
          <Page fullWidth>
            <div className="top_head_nav">
              <HorizontalStack align="space-between" blockAlign="center">
                <Text variant="headingLg" as="h5" fontWeight="semibold">
                  {isEdit ? "Edit Funnel" : "New Funnel"}
                </Text>
                <ButtonGroup>
                  <Button onClick={handleBack} disabled={step === 1}>
                    Back
                  </Button>
                  <Button primary onClick={handleNext} disabled={disable}>
                    {step === 3 ? 'Submit' : 'Next'}
                  </Button>
                </ButtonGroup>
              </HorizontalStack>
            </div>

            {/* stpe row */}
            <ul className="steps_funnel">
              <li className={`${step <= 3 && step >= 1 ? "active" : ""}`}>
                {step <= 3 && step > 1 ? (
                  <span className="complete_step">
                    <Icon source={TickSmallMinor} />
                  </span>
                ) : (
                  <span>1</span>
                )}
                <Text variant="headingMd" as="h5" fontWeight="semibold">
                  Create Funnel
                </Text>
              </li>
              <li className={`${step <= 3 && step >= 2 ? "active" : ""}`}>
                {step <= 3 && step > 2 ? (
                  <span className="complete_step">
                    <Icon source={TickSmallMinor} />
                  </span>
                ) : (
                  <span>2</span>
                )}
                <Text variant="headingMd" as="h5" fontWeight="semibold">
                  Funnels Rules
                </Text>
              </li>
              <li className={`${step === 3 ? "active" : ""}`}>

                <span>3</span>
                <Text variant="headingMd" as="h5" fontWeight="semibold">
                  {" "}
                  Set up offers
                </Text>
              </li>
            </ul>

            {/* steps */}
            {step === 1 && <CreateFunnel handleNext={handleNext} />}
            {step === 2 && <FunnelRules isEdit={isEdit} isDraft={isDraft} />}
            {step === 3 && <>{funnelData?.[0]?.offer_type === "upsell" ? <SetupUpsellOfferMain isEdit={isEdit} isDraft={isDraft} /> : <SetupOffersmain isEdit={isEdit} isDraft={isDraft} />}</>}

            <ModalCommon
              width={false}
              title={success ? "Congratulations" : "Error"}
              open={openModal}
              cancle={success && !isEdit ? "Create New" : "Cancel"}
              confirmation={success ? "View Funnels" : "Ok"}
              cancleHandleChange={newFunnelAddHandler}
              destructive={success ? false : true}
              confirmHandle={confirmHandle}
              body={
                <div>
                  {text}
                </div>
              }
            />
          </Page>
        </div>

        {/* Message Modal */}
        <ModalCommon
          width={false}
          title={"Message"}
          open={msgModal}
          cancle="Cancel"
          confirmation="Ok"
          cancleHandleChange={openMsgModal}
          destructive={false}
          confirmHandle={confirmMsgmodal}
          body={
            <div>
              {text}
            </div>
          }
        />
        {/* draf modal */}
        <ModalCommon
          width={false}
          title={"Draft"}
          open={drafModal}
          cancle="No"
          confirmation="Yes"
          cancleHandleChange={openDrafModal}
          destructive={false}
          confirmHandle={confirmDrafmodal}
          body={
            <div>
              Do you want to continue where you left off ?
            </div>
          }
        />

        {/* draf Modal */}
        {/* <ModalCommon
          width={false}
          title={"draf"}
          open={isModalOpen}
          cancle="Cancel"
          confirmation="Ok"
          // cancleHandleChange={openMsgModal}
          destructive={false}
          // confirmHandle={confirmMsgmodal}
          body={
            <div>
              <h2>
                "jjjjjjjjjjjjjjjjj"
              </h2>
            </div>
          }
        /> */}
      </>

  );
}
