import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: []
};

export const funnelSlice = createSlice({
    name: "funnel",
    initialState,
    reducers: {
        FunnelList: (state, action) => {
            state.data = action.payload;
        },
        UpdateFunnel: (state, action) => {
            const { id, updatedFields } = action.payload;
            const index = state.data.findIndex(
                (item) => item.id === id
            );
            if (index !== -1) {
                state.data[index] = {
                    ...state.data[index],
                    ...updatedFields,
                };
            }
        },
        deleteFunnel: (state, action) => {
            state.data =
            state.data.filter(
                (item, index) => item?.id !== action.payload
            );
        }
    },
});

// Action creators are generated for each case reducer function
export const { FunnelList, UpdateFunnel, deleteFunnel } = funnelSlice.actions;

export default funnelSlice.reducer;
