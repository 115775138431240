/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Checkbox, HorizontalGrid, Icon, Tag, Text, TextField, Tooltip, VerticalStack } from '@shopify/polaris'
import React, { useCallback, useEffect, useState, } from 'react'
import { QuestionMarkInverseMinor } from "@shopify/polaris-icons";

import AddCustomField from '../AddCustomField'
import { useDispatch } from 'react-redux';
import { deleteupdateOfferData, updateOfferData } from '../../../../../Redux/features/NewFunnel/newfunnelSlice';
import ProductModal from '../../Product_Modal/ProductModal';

const Advanced = ({ item, handleAddField, handleDeleteField, addField, funnelData }) => {
    const dispatch = useDispatch();
    let queue = []
    const setupHandleChange = useCallback((value, name) => {
        const updatedFields = {
            [name]: value,
        };
        dispatch(updateOfferData({ offer_number: item?.offer_number, offer_name: item?.offer_name, updatedFields }));
    }, [funnelData]);

    // Variants resource picker---------------------------
    const [openVariantsPicker, setOpenVariantsPicker] = useState(false);
    const [variants, setVariants] = useState([])

    //   cancleVarint
    const cancleVariant = useCallback(
        () => setOpenVariantsPicker(!openVariantsPicker),
        [openVariantsPicker]);

    useEffect(() => {
        // variants...
        if (item?.product_id?.length > 0) {
            item?.product_id?.map((item) => {
                queue.push(...item?.variants)
                setVariants(queue);
            })
        } else {
            setVariants([]);
        }
    }, [item?.product_id]);


    useEffect(() => {
        if (item?.maximum_quantity_limit > item?.minimum_quantity_limit) {
            const updatedFields = {
                "maximum_quantity_limit": item?.maximum_quantity_limit,
            };
            dispatch(updateOfferData({ offer_number: item?.offer_number, offer_name: item?.offer_name, updatedFields }));
        } else {
            const updatedFields = {
                "maximum_quantity_limit": item?.minimum_quantity_limit,
            };
            dispatch(updateOfferData({ offer_number: item?.offer_number, offer_name: item?.offer_name, updatedFields }));
        }
    }, [item?.minimum_quantity_limit, item?.maximum_quantity_limit])

    const listSelectedvarinatTags =
        (item.exclude_product_variants_list?.length > 0) && (item?.product_id?.length > 0) ? (
            <div>
                {item.exclude_product_variants_list?.map(
                    (product) => (
                        <Tag
                            onRemove={() =>
                                dispatch(deleteupdateOfferData({ offer_number: item?.offer_number, offer_name: item?.offer_name, updatedFields: product.id, name: "Offer_Variants" }))
                            }
                            key={product.id}
                        >
                            <div>
                                <span>{product.title}</span>
                            </div>
                        </Tag>
                    )
                )}
            </div>
        ) : null;

    return (
        <>
            <HorizontalGrid gap="4" columns={{ md: 2 }}>
                <VerticalStack>
                    <Text variant="headingMd" as="h5">
                        <div className="d-flex align-center">
                            Advanced
                            <span className="ps-1">
                                <Tooltip content="Advanced">
                                    <Icon source={QuestionMarkInverseMinor} color="base" />
                                </Tooltip>
                            </span>
                        </div>
                    </Text>
                    <p>Select a product that will be offered to your buyer.</p>
                </VerticalStack>
                <VerticalStack>
                    <div className="mt-2">
                        <Checkbox
                            label={
                                <>
                                    <div className="d-flex">
                                        Include free shipping for clients who accept this
                                        offer{" "}
                                        <span className="ps-1">
                                            <Tooltip content="IncludeCart / product page funnels - apply free shipping on the entire order. Thank you page funnels - apply free shipping on all upsell products.">
                                                <Icon source={QuestionMarkInverseMinor} color="base" />
                                            </Tooltip>
                                        </span>
                                    </div>
                                </>
                            }
                            checked={item?.include_free_shipping}
                            onChange={(value) =>
                                setupHandleChange(value, "include_free_shipping")
                            }
                        />
                    </div>
                    <div className="mt-2">
                        <Checkbox
                            label="Limit the number of products your buyer could buy"
                            checked={item?.maximum_limit}
                            onChange={(value) =>
                                setupHandleChange(value, "maximum_limit")
                            }
                        />
                        {item?.maximum_limit && (
                            <div className="mt-2 select_limit">
                                <TextField
                                    type="number"
                                    label="Your buyer could buy"
                                    helpText="units of this product"
                                    value={item?.maximum_quantity_limit}
                                    onChange={(value) =>
                                        setupHandleChange(Number(value) > 1 ? Number(value) : 1, "maximum_quantity_limit")
                                    }
                                />
                            </div>
                        )}
                    </div>
                    <div className="mt-2">
                        <Checkbox
                            label="Set a minimal number of products your buyer must buy"
                            checked={item?.minimum_limit}
                            onChange={(value) =>
                                setupHandleChange(value, "minimum_limit")

                            }
                        />
                        {item?.minimum_limit && (
                            <div className="mt-2 select_limit">
                                <TextField
                                    type="number"
                                    label="Your buyer must buy at least"
                                    helpText="units of this product"
                                    value={item?.minimum_quantity_limit}
                                    onChange={(value) =>
                                        setupHandleChange(Number(value) > 1 ? Number(value) : 1, "minimum_quantity_limit")
                                    }
                                />
                            </div>
                        )}
                    </div>
                    <div className="mt-2">
                        <Checkbox
                            label={
                                <>
                                    <div className="d-flex">
                                        Exclude variants{" "}
                                        <span className="ps-1">
                                            <Tooltip content="Check this box if you want to exclude some variants from this offer.">
                                                <Icon source={QuestionMarkInverseMinor} color="base" />
                                            </Tooltip>
                                        </span>
                                    </div>
                                </>
                            }
                            checked={item?.exclude_variants && item.product_id.length > 0}
                            onChange={(value) =>
                                setupHandleChange(value, "exclude_variants")

                            }
                            disabled={item?.product_id?.length === 0}
                        />
                        {item?.exclude_variants && item.product_id.length > 0 && (
                            <div className="mt-2 product_select">

                                {/* Select variants */}
                                <div
                                    onClick={() => {
                                        setOpenVariantsPicker(true);
                                    }}>
                                    <TextField
                                        value=""
                                        placeholder="Select variants"
                                        verticalContent={listSelectedvarinatTags}
                                        labelAction={{
                                            content: (
                                                <Icon
                                                    source={QuestionMarkInverseMinor}
                                                    color="base"
                                                />
                                            ),
                                        }}
                                        autoComplete="off"
                                    />
                                </div>
                            </div>
                        )}
                    </div>

                    {/* <div className="mt-2">
                        <Checkbox
                            label={
                                <>
                                    <div className="d-flex">
                                        This is a personalized product{" "}
                                        <span className="ps-1">
                                            <Icon
                                                source={QuestionMarkInverseMinor}
                                                color="base"
                                            />
                                        </span>
                                    </div>
                                </>
                            }
                            checked={item?.personalisedProduct}
                            onChange={(value) =>
                                setupHandleChange(
                                    item?.id,
                                    value,
                                    "personalisedProduct"
                                )
                            }
                        />
                    </div> */}

                    {item?.personalisedProduct && (
                        <div className="mt-2">
                            {addField && (
                                <div className="box_wrap mx-0">
                                    <AddCustomField handleDelete={handleDeleteField} />
                                </div>
                            )}
                            <Button onClick={handleAddField}>
                                Add a Custom Field
                            </Button>
                        </div>
                    )}
                </VerticalStack>
            </HorizontalGrid>

            {/* Select variants resource picker */}
            {
                openVariantsPicker &&
                <ProductModal modalName={'Variants'} open={openVariantsPicker} cancle={cancleVariant} data={variants} item={item} type={"Offer_Variants"} />
            }
        </>
    )
}

export default Advanced