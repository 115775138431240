/* eslint-disable react-hooks/exhaustive-deps */
import { HorizontalGrid, Icon, Tag, Text, TextField, VerticalStack } from '@shopify/polaris'
import React, { useCallback, useEffect, useState } from 'react'
import { QuestionMarkInverseMinor } from "@shopify/polaris-icons";
import ProductModal from '../../../Product_Modal/ProductModal';
import { SERVER_BASE_URL } from '../../../../../../config';
import { deleteupdateOfferData } from '../../../../../../Redux/features/NewFunnel/newfunnelSlice';
import { useDispatch, useSelector } from 'react-redux';
import axios from "../../../../../../Helper/axios";
import { setProductData } from '../../../../../../Redux/features/NewFunnel/upsellTabsSlice';
import ToastCommon from '../../../../../../Common/ToastCommon';

const UpsellProduct = ({ item, funnelData, triggerDataName }) => {
    const [openProductPicker, setIOpenProductPicker] = useState(false);
    // const [productdata, setProductData] = useState([])
    const UpsellproductdataRedux = useSelector((state) => state?.upsellTabs?.productData);
    const [loading, setLoading] = useState(false)
    const [toaststatus, setToastStatus] = useState(false)
    const [toastmsg, setToastMSg] = useState("")
    const dispatch = useDispatch();
    const triggerData = useSelector(state => state?.newfunnel?.data[1]?.upsell_product_detail)
    let upsellProductData = UpsellproductdataRedux?.filter((product) => product?.admin_graphql_api_id !== triggerData?.id && product?.status === "active" && product?.published_at !== null);

    useEffect(() => {
        if (triggerData && item?.upsell_product) {
            if (triggerData?.id === item?.upsell_product[0]?.admin_graphql_api_id) {
                dispatch(deleteupdateOfferData({ offer_number: item?.offer_number, offer_name: item?.offer_name, updatedFields: item?.upsell_product[0]?.admin_graphql_api_id, name: "upsell_product_repeat" }))
            }
        }
    }, [triggerData])

    const cancleVariant = useCallback(
        () => setIOpenProductPicker(!openProductPicker),
        [openProductPicker]);

    const productDataHandler = () => {
        if (UpsellproductdataRedux?.length === 0) {
            setLoading(true)
            axios({
                method: "get",
                baseURL: SERVER_BASE_URL,
                url: "/api/shopify/get-products",
            }).then(
                (result) => {
                    dispatch(setProductData(result?.data?.products))
                    setLoading(false)
                },
                (error) => {
                    setIOpenProductPicker(false)
                    setLoading(false)
                    setToastStatus(true)
                    setToastMSg("Something went wrong while fetching product data.")
                }
            );
        }

    }

    // useEffect(() => {
    //     productDataHandler();
    // }, [])

    const listSelectedproductTags =
        item?.upsell_product?.length > 0 ? (
            item?.upsell_product[0] ? (
                <div>
                    <Tag
                        onRemove={() => dispatch(deleteupdateOfferData({ offer_number: item?.offer_number, offer_name: item?.offer_name, updatedFields: item?.upsell_product[0]?.id, name: "upsell_product" }))}
                    >
                        <div>
                            <span>{item?.upsell_product[0]?.title}</span>
                        </div>
                    </Tag>

                </div>
            ) : null
        ) : null


    return (
        <>
            <HorizontalGrid gap="4" columns={{ md: 2 }}>
                <VerticalStack>
                    <Text variant="headingMd" as="h5">
                        Select upsell product
                    </Text>
                    <p>{`Select a product that will replace ${triggerDataName ? triggerDataName : ""} when your offer is accepted.`}</p>
                </VerticalStack>
                <VerticalStack>
                    <div className="mt-2 product_select">
                        {/* select product field */}
                        <div
                            onClick={() => {
                                setIOpenProductPicker(true);
                                productDataHandler()
                            }}
                        >
                            <TextField
                                value=""
                                placeholder="Select a product"
                                verticalContent={listSelectedproductTags}
                                labelAction={{
                                    content: (
                                        <Icon
                                            source={QuestionMarkInverseMinor}
                                            color="base"
                                        />
                                    ),
                                }}
                                autoComplete="off"
                            />
                        </div>
                    </div>
                </VerticalStack>
            </HorizontalGrid>

            {/* Select variants resource picker */}
            {
                openProductPicker &&
                <ProductModal modalName={'Products'} open={openProductPicker} cancle={cancleVariant} data={upsellProductData} item={item} type="upsell_product" loading={loading} />
            }
            
            {
          toaststatus && (
            <ToastCommon
              status={toaststatus}
              msg={toastmsg}
              setToastStatus={setToastStatus}
              error={true}
              time={2500}
            />
          )
        }
        </>
    )
}

export default UpsellProduct



