import axios from "axios";
import { getSessionToken } from "@shopify/app-bridge/utilities";
import { createApp } from "@shopify/app-bridge";
import { SHOPIFY_API_KEY } from "../config";
// import { useLocation } from "react-router";

const instance = axios.create();
// const location = useLocation();

const urlSearchParams = new URLSearchParams(window.location.search);
const host =
  urlSearchParams.get("host") ||
  "YWRtaW4uc2hvcGlmeS5jb20vc3RvcmUva2VzaGF2LWRlbW8tc3RvcmU";

const config = {
  apiKey: SHOPIFY_API_KEY,
  host,
  forceRedirect: true,
};

// const config = {
//   apiKey: SHOPIFY_API_KEY,
//   // host: "YWRtaW4uc2hvcGlmeS5jb20vc3RvcmUva2VzaGF2LWRlbW8tc3RvcmU",
//   host: new URLSearchParams(window.location.search).get("host","YWRtaW4uc2hvcGlmeS5jb20vc3RvcmUva2VzaGF2LWRlbW8tc3RvcmU"),
//   forceRedirect: true,
// };
const app = createApp(config);

instance.interceptors.request.use(function (config) {
  return getSessionToken(app) // requires a Shopify App Bridge instance
    .then((token) => {
      // Append your request headers with an authenticated token
      config.headers.Authorization = `Bearer ${token}`;
      config.headers["User-Agent"] = "alpha";
      return config;
    });
});

axios.interceptors.response.use((response) => {
  return response;
});

// Export your Axios instance to use within your app
export default instance;
