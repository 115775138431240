/* eslint-disable react-hooks/exhaustive-deps */
import axios from "../../Helper/axios"
import React, { useEffect, useState } from 'react'
import { SERVER_BASE_URL } from '../../config';
import {  Card,  Icon, Page, Text, } from '@shopify/polaris'
import DatePickerCustome from "../../Common/DatePickerCustome";
import { BarChart, LineChart } from "@shopify/polaris-viz";
import { ViewMinor, AnalyticsMajor } from '@shopify/polaris-icons';
import { useParams } from "react-router-dom";
import LoginRequired from "../../Common/LoginRequired";


export default function Dashboard() {
    const params = useParams();
    const [dateObj, setDateObj] = useState()
    const [RevenuedateObj, setRevenueDateObj] = useState()
    const auth_flow_link = `${SERVER_BASE_URL}/auth/login${window.location.search}`
    const [redirectionRequired, setRedirectionRequired] = useState(false)
    const [funnelViewsData, setFunnelViewsData] = useState([])
    const [revenueGrowthData, setRevenueGrowthData] = useState([])
    useEffect(() => {
        axios({
            method: params?.id ? "put" : "post",
            baseURL: SERVER_BASE_URL,
            // url: "/api/funnel-views-growth/",
            url: params?.id ? `/api/funnel-views-growth/${params?.id}/` : "/api/funnel-views-growth/",
            data: dateObj
        }).then(
            (result) => {
                if (result.data.login_required) {
                    alert("Please Login Again.")
                    setRedirectionRequired(true)
                }
                else {
                    setFunnelViewsData(result?.data?.data)
                }
            },
            (error) => {
                if (error.response.data.login_required) {
                    alert("Please Login Again.")
                    setRedirectionRequired(true)
                }
            }
        );
    }, [dateObj]);

    useEffect(() => {
        axios({
            method: params?.id ? "put" : "post",
            baseURL: SERVER_BASE_URL,
            url: params?.id ? `/api/funnel-revenue-growth/${params?.id}/` : "/api/funnel-revenue-growth/",
            data: RevenuedateObj
        }).then(
            (result) => {
                if (result.data.login_required) {
                    alert("Please Login Again.")
                    setRedirectionRequired(true)
                }
                else {
                    setRevenueGrowthData(result?.data?.data)
                }
            },
            (error) => {
                if (error.response.data.login_required) {
                    alert("Please Login Again.")
                    setRedirectionRequired(true)
                }
            }
        );
    }, [RevenuedateObj]);

    if (redirectionRequired) {
        return (
            <LoginRequired auth_flow_link={auth_flow_link} />

        )
    }
    const barChartData = [
        {
            name: 'Revenue growth',
            color: '#008060',
            // data: [
            //     {
            //         key: 'Aug 22',
            //         value: 1,
            //     },
            //     {
            //         key: 'Aug 31',
            //         value: 0.5,
            //     },
            //     {
            //         key: 'Sep 2',
            //         value: 0.1,
            //     },
            //     {
            //         key: 'Sep 15',
            //         value: 0.9,
            //     },
            //     {
            //         key: 'oct 1',
            //         value: 0.6,
            //     },
            //     {
            //         key: 'oct 3',
            //         value: 0.3,
            //     },
            //     {
            //         key: 'oct 4',
            //         value: 5,
            //     },
            //     {
            //         key: 'oct 6',
            //         value: 1,
            //     },
            //     {
            //         key: 'oct 7',
            //         value: 1.5,
            //     },
            //     {
            //         key: 'oct 8',
            //         value: 0.5,
            //     },
            //     {
            //         key: 'oct 9',
            //         value: 3,
            //     },
            //     {
            //         key: 'oct 10',
            //         value: 0.2,
            //     },
            // ],
            data: revenueGrowthData,

        }
    ];
    const lineChartData = [
        {
            name: 'Funnel Views',
            color: 'red',
            data: funnelViewsData,
            // data: [
            //     {
            //         key: 'Aug 22',
            //         value: 10,
            //     },
            //     {
            //         key: 'Aug 31',
            //         value: 150,
            //     },
            //     {
            //         key: 'Sep 2',
            //         value: 200,
            //     },
            //     {
            //         key: 'Sep 15',
            //         value: 90,
            //     },
            //     {
            //         key: 'oct 1',
            //         value: 3,
            //     },
            //     {
            //         key: 'oct 3',
            //         value: 80,
            //     },
            //     {
            //         key: 'oct 4',
            //         value: 15,
            //     },
            //     {
            //         key: 'oct 6',
            //         value: 501,
            //     },
            //     {
            //         key: 'oct 7',
            //         value: 1.5,
            //     },
            //     {
            //         key: 'oct 8',
            //         value: 0.5,
            //     },
            //     {
            //         key: 'oct 9',
            //         value: 39,
            //     },
            //     {
            //         key: 'oct 10',
            //         value: 21,
            //     },
            // ],
        }
    ];

    return (
        <>
            <div className="main_layout_page">
                <Page fullWidth>
                    <div className="chart_wrap mt-4">
                        <Card>
                            <div className="chart_icon">
                                <span>
                                    <Icon
                                        source={ViewMinor}
                                        tone="base"
                                    />
                                </span>
                                <Text variant="headingLg" as="h5" fontWeight='semibold'>
                                    Funnel views
                                </Text>
                            </div>
                            <div className="datepicker my-4">
                                <DatePickerCustome setDateObj={setDateObj} type="funnelViewsData" />
                            </div>
                            <LineChart
                                theme='Light'
                                showLegend={false}
                                data={lineChartData}
                            />
                        </Card>
                    </div>
                    <div className="chart_wrap mt-4">
                        <Card>
                            <div className="chart_icon">
                                <span>
                                    <Icon
                                        source={AnalyticsMajor}
                                        tone="base"
                                    />
                                </span>
                                <Text variant="headingLg" as="h5" fontWeight='semibold'>
                                    Revenue growth
                                </Text>
                            </div>
                            <div className="datepicker my-4">
                                <DatePickerCustome setRevenueDateObj={setRevenueDateObj} type="RevenueGrowthData" />
                            </div>
                            <BarChart
                                theme='Light'
                                showLegend={false}
                                data={barChartData}
                            />
                        </Card>
                    </div>


                </Page>
            </div>

        </>
    )
}
