/* eslint-disable array-callback-return */
import { HorizontalGrid, Icon, Tag, Text, TextField, VerticalStack } from '@shopify/polaris'
import React, { useState } from 'react'
import { QuestionMarkInverseMinor } from "@shopify/polaris-icons";
import ProductModal from '../../Product_Modal/ProductModal';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { deleteupdateOfferData } from '../../../../../Redux/features/NewFunnel/newfunnelSlice';


const VarintUpsell = ({ item, index, funnelData }) => {
    const variantData = useSelector(state => state?.newfunnel?.data[1]?.upsell_product_detail?.variants);
    const triggerData = funnelData?.flatMap(offer => {
        if (offer?.offer_number === item?.offer_number) {
            return offer?.variant_trigger;
        }
        return null;
    });
    const filteredData = triggerData.filter(value => value !== null);

    const findUniqueObjects = (arr1, arr2) => {
        const uniqueInArr1 = arr1?.filter(obj1 => !arr2?.find(obj2 => obj2?.id === obj1?.id));
        const uniqueInArr2 = arr2?.filter(obj2 => !arr1?.find(obj1 => obj1?.id === obj2?.id));
        return uniqueInArr1?.concat(uniqueInArr2);
    }
    const uniqueVariants = findUniqueObjects(variantData, filteredData);
    
    const dispatch = useDispatch();
    const [openVariantsPicker, setOpenVariantsPicker] = useState(false);

    const cancleVariant = useCallback(
        () => setOpenVariantsPicker(!openVariantsPicker),
        [openVariantsPicker]);

    const listSelectedproductTags =
        item.variant_upsell?.length > 0 ? (
            <div>
                {item.variant_upsell?.map((variant) => (
                    <Tag
                        onRemove={() => dispatch(deleteupdateOfferData({ offer_number: item?.offer_number, offer_name: item?.offer_name, updatedFields: variant.id, name: "variant_upsell" }))}
                        key={variant.id}
                    >
                        <div>
                            <span>{variant.title}</span>
                        </div>
                    </Tag>
                ))}
            </div>
        ) : null;


    return (
        <>
            <HorizontalGrid gap="4" columns={{ md: 2 }}>
                <VerticalStack>
                    <Text variant="headingMd" as="h5">
                        Variant upsell
                    </Text>
                    <p>If your offer is accepted, this variant will replace the trigger variants in the cart.</p>
                </VerticalStack>
                <VerticalStack>
                    <div className="mt-2 product_select">
                        {/* select product field */}
                        <div onClick={() => { setOpenVariantsPicker(true); }}>
                            <TextField
                                value=""
                                placeholder="Select a Variant"
                                verticalContent={listSelectedproductTags}
                                labelAction={{
                                    content: (
                                        <Icon
                                            source={QuestionMarkInverseMinor}
                                            color="base"
                                        />
                                    ),
                                }}
                                autoComplete="off"
                            />
                        </div>

                    </div>
                </VerticalStack>
            </HorizontalGrid>
            {/* Select variants resource picker */}
            {
                openVariantsPicker &&
                <ProductModal modalName={'Variants'} open={openVariantsPicker} cancle={cancleVariant} data={uniqueVariants} item={item} type="variant_upsell" />
            }
        </>
    )
}

export default VarintUpsell;
