/* eslint-disable react-hooks/exhaustive-deps */
import { Checkbox, ChoiceList, HorizontalGrid, Icon, Popover, Select, Tag, Text, TextField, VerticalStack, Tooltip } from "@shopify/polaris";
import React, { useCallback, useEffect, useState } from "react";
import { QuestionMarkInverseMinor } from "@shopify/polaris-icons";

import ReactCountryFlag from "react-country-flag";
import Countries from "../../../../Json/countries.json";
import { DatePickerCommon } from "../../../../Common/DatePickerCommon";

// import MyResourcePicker from "../../../../Common/MyResourcePicker";
import { useDispatch, useSelector } from "react-redux";
import {
  Updateupsell,
  deleteFunnelRulesProductList,
  deleteFunnelRulescollections,
  deletetargetcountries,
  devices_showon,
  targetcountries,
  updateSelectedFunnel,
} from "../../../../Redux/features/NewFunnel/newfunnelSlice";
import Trigger from "./Trigger";
import ProductModal from "../Product_Modal/ProductModal";
import axios from "../../../../Helper/axios";
import { SERVER_BASE_URL } from "../../../../config";
import ToastCommon from "../../../../Common/ToastCommon";
import { setCollectionData, setProductData } from "../../../../Redux/features/NewFunnel/upsellTabsSlice";

export default function FunnelRules() {
  const selectedIndex$ = useSelector((state) => state?.newfunnel?.seleted_step);
  const productdata = useSelector((state) => state?.upsellTabs?.productData);
  const collectionsdata = useSelector((state) => state?.upsellTabs?.collectionData);
  const Trigger$ = useSelector((state) => state?.newfunnel?.data);
  const dispatch = useDispatch();
  const [productLoading, setProductLoading] = useState(false)
  const [collectionLoading, setCollectionLoading] = useState(false)
  const [toaststatus, setToastStatus] = useState(false)
  const [toastmsg, setToastMSg] = useState("")



  useEffect(() => {
    if (Trigger$[0].offer_type === "upsell") {
      const newUpdatedFields = {
        show_for_all: false,
        show_for_specific_product: false,
        products_list: [],
        exclude_variants: false,
        excluded_variants_list: [],
        show_for_specific_collection: false,
        collections_list: [],
        // do_not_show_for_specific_product: false,
        // do_not_show_for_specific_products_list: [],
        // do_not_show_for_specific_collection: false,
        // do_not_show_for_specific_collections_list: [],
      }
      dispatch(Updateupsell(newUpdatedFields));
    }
    else {
      const newUpdatedFields = {
        upsell_product_detail: null
      }
      dispatch(Updateupsell(newUpdatedFields));
    }
  }, [])

  //products
  const handleProductChange = useCallback(
    (newValue, name, id) => {
      const updatedFields = {
        [name]: newValue,
      };
      dispatch(updateSelectedFunnel(updatedFields));
    },
    [Trigger$]
  );

  const handleChange = useCallback(
    (value) => {
      dispatch(devices_showon(value[0]));
    },
    [Trigger$]
  );

  //country
  const handleCountryChange = useCallback(
    (newValue, name) => {
      const updatedFields = {
        [name]: newValue,
      };
      dispatch(updateSelectedFunnel(updatedFields));
    },
    [Trigger$]
  );

  //prority
  const handlePrority = useCallback(
    (newValue, name) => {
      const updatedFields = {
        [name]: newValue,
      };
      dispatch(updateSelectedFunnel(updatedFields));
    },
    [Trigger$]
  );

  const handleSelectChange = useCallback((name, value) => {
    const updatedFields = {
      [name]: parseInt(value),
    };
    dispatch(updateSelectedFunnel(updatedFields));
  }, []);

  const options = [
    { label: "1", value: 1 },
    { label: "2", value: 2 },
    { label: "3", value: 3 },
    { label: "4", value: 4 },
    { label: "5", value: 5 },
    { label: "6", value: 6 },
    { label: "7", value: 7 },
    { label: "8", value: 8 },
    { label: "9", value: 9 },
    { label: "10", value: 10 },
  ];

  const handleStartTime = useCallback(
    (newValue, name) => {
      const updatedFields = {
        [name]: newValue,
      };
      dispatch(updateSelectedFunnel(updatedFields));
    },
    [Trigger$]
  );

  const handleEndTime = useCallback(
    (newValue, name) => {
      const updatedFields = {
        [name]: newValue,
      };
      dispatch(updateSelectedFunnel(updatedFields));
    },
    [Trigger$]
  );
  const [active, setActive] = useState(false);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [searchText, setSearchText] = useState("");

  const [countries, setAvailableCountries] = useState(Countries);
  //  set old counties
  useEffect(() => {
    if (Trigger$[1]?.target_countries?.length > 0) {
      const notSelectedCountries = countries.filter(
        (obj1) =>
          !Trigger$[1]?.target_countries.some((obj2) => obj1.code === obj2.code)
      );
      setAvailableCountries(notSelectedCountries);
      let countries_temp = [];
      Trigger$[1]?.target_countries?.forEach((element) => {
        countries_temp.push(element);
      });
      setSelectedCountries(countries_temp);
    }
  }, []);

  const toggleActive = () => {
    setActive(!active);
  };

  const handleRemove = useCallback(
    (code, name) => {
      setSelectedCountries(
        selectedCountries.filter(
          (selectedCountry) => selectedCountry?.code !== code
        )
      );
      setAvailableCountries([
        ...countries,
        {
          code: code,
          name: name,
        },
      ]);
    },
    [countries]
  );

  const handleSelection = useCallback(
    (code, name) => {
      if (!selectedCountries.includes(code)) {
        setSelectedCountries([
          ...selectedCountries,
          {
            code: code,
            name: name,
          },
        ]);
        setAvailableCountries(
          countries.filter((selectedCountry) => selectedCountry.code !== code)
        );
      }
    },
    [selectedCountries, countries]
  );

  useEffect(() => {
    const contryname = [];
    selectedCountries?.map((country) => {
      return contryname.push(country);
    });
    if (contryname.length > 0) {
      dispatch(targetcountries(contryname));
    }
  }, [selectedCountries]);

  const handleSearch = (text) => {
    setSearchText(text);
  };

  const verticalContentMarkup =
    Trigger$[selectedIndex$]?.target_countries?.length > 0 ? (
      <div>
        {Trigger$[selectedIndex$]?.target_countries.map((country) => (
          <Tag
            key={country?.code}
            onRemove={() =>
              handleRemove(
                country?.code,
                country?.name,
                dispatch(deletetargetcountries(country?.code))
              )
            }
          >
            <ReactCountryFlag
              countryCode={country?.code}
              svg
              country={country?.code}
            />{" "}
            {country?.name}
          </Tag>
        ))}
      </div>
    ) : null;
  const activator = (
    <div onClick={toggleActive} className="selectBtn">
      <TextField
        value={searchText}
        // onFocus={toggleActive}
        onChange={handleSearch}
        placeholder="Select a countries"
        verticalContent={verticalContentMarkup}
        autoComplete="off"
      />
    </div>
  );

  const filteredCountries = countries.filter((country) => {
    return country.name.toLowerCase().includes(searchText.toLowerCase());
  });

  const [selectedProducts, setSelectedProducts] = useState([]);

  const [selectedCollections, setSelectedCollections] = useState([]);

  useEffect(() => {
    setSelectedProducts(Trigger$[selectedIndex$]?.do_not_show_for_specific_products_list)
    setSelectedCollections(Trigger$[selectedIndex$]?.do_not_show_for_specific_collections_list)
  }, [selectedProducts, selectedCollections])

  const listSelectedCollections =
    Trigger$[selectedIndex$]?.do_not_show_for_specific_collections_list?.length > 0 ? (
      <div>
        {Trigger$[selectedIndex$]?.do_not_show_for_specific_collections_list?.map((collection) => (
          <Tag
            onRemove={() =>
              handleRemovecollection(
                collection.id,
                dispatch(deleteFunnelRulescollections(collection.id))
              )
            }
            key={collection.id}
          >
            <div>
              <span>{collection.title}</span>
            </div>
          </Tag>
        ))}
      </div>
    ) : null;

  const handleRemovecollection = (collectionId) => {
    const updatedCollections = selectedCollections.filter(
      (collection) => collection.id !== collectionId
    );
    setSelectedCollections(updatedCollections);
  };


  const listSelectedproductTags =
    Trigger$[selectedIndex$]?.do_not_show_for_specific_products_list?.length >
      0 ? (
      <div>
        {Trigger$[selectedIndex$]?.do_not_show_for_specific_products_list?.map(
          (product) => (
            <Tag
              onRemove={() =>
                handleRemoveProduct(
                  product.id,
                  dispatch(deleteFunnelRulesProductList(product.id))
                )
              }
              key={product.id}
            >
              <div>
                <span>{product.title}</span>
              </div>
            </Tag>
          )
        )}
      </div>
    ) : null;

  const handleRemoveProduct = (productId) => {
    const updatedProducts = selectedProducts.filter(
      (product) => product.id !== productId
    );
    setSelectedProducts(updatedProducts);
  };

  // Product resource picker.....
  const [openProductPicker, setOpenProductPicker] = useState(false);

  // Cancle event of product modal
  const cancleProduct = useCallback(
    () => setOpenProductPicker(!openProductPicker),
    [openProductPicker]);

  // open product modal
  const openProdctModal = useCallback(() => {
    setOpenProductPicker(true);
    productData()
  }, [openProductPicker])

  // open product modal data
  const productData = () => {
    if (productdata?.length === 0) {
      setProductLoading(true)
      axios({
        method: "get",
        baseURL: SERVER_BASE_URL,
        url: "/api/shopify/get-products",
      }).then(
        (result) => {
          // dispatch(modaldata(result?.data?.products));
          let activeProductData = result?.data?.products?.filter((product) => product?.status === "active" && product?.published_at !== null);
          // setProductData(activeProductData)
          dispatch(setProductData(activeProductData))
          setProductLoading(false)
        },
        (error) => {
          setOpenProductPicker(false)
          setProductLoading(false)
          setToastStatus(true)
          setToastMSg("Something went wrong while fetching product data.")

        }
      );
    }

  }

  const CollectionData = () => {
    if (collectionsdata?.length === 0) {
      // setProductLoading(true)
      setCollectionLoading(true)
      axios({
        method: "get",
        baseURL: SERVER_BASE_URL,
        url: "/api/shopify/get-collections",
      }).then(
        (result) => {
          dispatch(setCollectionData(result?.data?.collections))

          setCollectionLoading(false)

        },
        (error) => {
          setOpenProductPicker(false)
          setCollectionLoading(false)
          setToastStatus(true)
          setToastMSg("Something went wrong while fetching collection data.")
        }
      );
    }
  }





  // Specific Collections.....
  const [openCollectionsPicker, setOpenCollectionsPicker] = useState(false);

  // Cancle event of product modal
  const cancleCollection = useCallback(
    () => setOpenCollectionsPicker(!openCollectionsPicker),
    [openCollectionsPicker]);


  // useEffect(() => {
  //   productData();
  //   CollectionData()
  // }, [])

  return (
    <>
      <Trigger />
      <div className="box_wrap funnel_rules">

        {/* funnel rules */}
        <HorizontalGrid gap="4" columns={{ lg: 2 }}>

          <VerticalStack>

            <Text variant="headingMd" as="h5">
              Funnel rules <span className="textdisabled">(Optional)</span>
            </Text>
            <p>Set optional rules for this funnel.</p>

            {/* specific products */}
            <div className="mt-4">
              <Checkbox
                label={
                  <div className="d-flex">
                    Do not show for specific products
                    <span className="ps-1">
                      <Tooltip content="The funnel will not show if any of these products are included in the cart / order.">
                        <Icon source={QuestionMarkInverseMinor} color="base" />
                      </Tooltip>
                    </span>
                  </div>
                }
                // checked={
                //   Trigger$[selectedIndex$]?.do_not_show_for_specific_product && !(productdata?.length === Trigger$[1]?.products_list?.length)
                // }
                checked={
                  Trigger$[selectedIndex$]?.do_not_show_for_specific_product
                }
                // disabled={productdata?.length === Trigger$[1]?.products_list?.length}
                onChange={() =>
                  handleProductChange(
                    !Trigger$[selectedIndex$]?.do_not_show_for_specific_product,
                    "do_not_show_for_specific_product"
                  )
                }
              />
              {/* {Trigger$[selectedIndex$]?.do_not_show_for_specific_product && !(productdata?.length === Trigger$[1]?.products_list?.length) && ( */}
              {Trigger$[selectedIndex$]?.do_not_show_for_specific_product && (
                <div
                  className="mt-2 product_select"
                  onClick={openProdctModal}
                >
                  <TextField
                    value=""
                    placeholder="Select a product"
                    verticalContent={listSelectedproductTags}
                    labelAction={{
                      content: (
                        <Icon source={QuestionMarkInverseMinor} color="base" />
                      ),
                    }}
                    autoComplete="off"
                  />
                </div>
              )}
            </div>

            {/* specific collections */}
            <div className="mt-4">
              <Checkbox
                label={
                  <>
                    <div className="d-flex">
                      Do not show for specific collections{" "}
                      <span className="ps-1">
                        <Tooltip content="The funnel will not show if products from these collections are included in the cart / order.">
                          <Icon source={QuestionMarkInverseMinor} color="base" />
                        </Tooltip>
                      </span>
                    </div>
                  </>
                }
                checked={
                  Trigger$[selectedIndex$]?.do_not_show_for_specific_collection
                }
                onChange={() =>
                  handleProductChange(
                    !Trigger$[selectedIndex$]
                      ?.do_not_show_for_specific_collection,
                    "do_not_show_for_specific_collection"
                  )
                }
              />
              {Trigger$[selectedIndex$]
                ?.do_not_show_for_specific_collection && (
                  <div
                    className="mt-2 product_select"
                    onClick={() => {
                      setOpenCollectionsPicker(true);
                      CollectionData()

                    }}
                  >
                    <TextField
                      value=""
                      placeholder="Select a collections"
                      verticalContent={listSelectedCollections}
                      labelAction={{
                        content: (
                          <Icon source={QuestionMarkInverseMinor} color="base" />
                        ),
                      }}
                      autoComplete="off"
                    />
                  </div>
                )}
            </div>

            {/* Show this funnel on */}
            <div className="mt-4 device_select">
              <ChoiceList
                title="Show this funnel on"
                choices={[
                  { label: "All devices", value: "both" },
                  { label: "Mobile only", value: "mobile" },
                  { label: "Desktop only", value: "desktop" },
                ]}
                selected={[Trigger$[selectedIndex$]?.devices_to_show_on]}
                onChange={(value) => {
                  handleChange(value);
                }}
              />
            </div>

            {/* Target specific countries */}
            <div className="mt-4">
              <Checkbox
                label={
                  <div className="d-flex">
                    Target specific countries
                    <span className="ps-1">
                      <Tooltip content="Check this box if you want show this funnel only in specific countries.">
                        <Icon source={QuestionMarkInverseMinor} color="base" />
                      </Tooltip>
                    </span>
                  </div>
                }
                // checked={checkedCountry || Trigger$[selectedIndex$]?.target_countries?.length >= 1}
                checked={Trigger$[selectedIndex$]?.target_countries_status}
                onChange={() => {
                  handleCountryChange(
                    !Trigger$[selectedIndex$]?.target_countries_status,
                    "target_countries_status"
                  );
                }}
              />
              {Trigger$[selectedIndex$]?.target_countries_status && (
                <div className="mt-2 country_select">
                  <Popover
                    active={active}
                    activator={activator}
                    onClose={toggleActive}
                    fullWidth
                  >
                    <Popover.Pane fixed>
                      <div
                        style={{ maxHeight: "300px", overflowY: "auto" }}
                        className="country_list"
                      >
                        {filteredCountries.map((country) => (
                          <div
                            key={country.code}
                            onClick={() =>
                              handleSelection(country.code, country.name)
                            }
                            style={{ cursor: "pointer", padding: "8px" }}
                          >
                            <ReactCountryFlag countryCode={country.code} svg />{" "}
                            {country.name}
                          </div>
                        ))}
                      </div>
                    </Popover.Pane>
                  </Popover>
                </div>
              )}
            </div>

            {/* Add funnel priority */}
            <div className="mt-4">
              <Checkbox
                label="Add funnel priority"
                checked={Trigger$[selectedIndex$]?.funnel_priority_status}
                onChange={() => {
                  handlePrority(
                    !Trigger$[selectedIndex$]?.funnel_priority_status,
                    "funnel_priority_status"
                  );
                }}
              />
              {Trigger$[selectedIndex$]?.funnel_priority_status && (
                <div className="mt-2">
                  <Select
                    label="Priority"
                    options={options}
                    onChange={(value) => {
                      handleSelectChange("funnel_priority", value);
                    }}
                    value={Trigger$[selectedIndex$]?.funnel_priority}
                    helpText="Priority will take effect in case two or more funnels are on the same page and have overlapping triggers. Use 1-10, when 1 is the highest priority."
                  />
                </div>
              )}
            </div>

            {/* Set start time */}
            <div className="mt-4">
              <Checkbox
                label="Set start time"
                checked={Trigger$[selectedIndex$]?.start_time_status}
                onChange={() => {
                  handleStartTime(
                    !Trigger$[selectedIndex$]?.start_time_status,
                    "start_time_status"
                  );
                }}
              />


              {/* {Trigger$[selectedIndex$]?.start_time_status && ( */}
              {
                Trigger$[selectedIndex$]?.start_time_status &&
                <div className="mt-2">
                  <DatePickerCommon
                    time="start_time"
                    oldSelectedDate={Trigger$[selectedIndex$]?.start_time}
                  />
                </div>
              }
              {/* )} */}

            </div>

            {/* Set end time */}
            <div className="mt-4">
              <Checkbox
                label="Set end time"
                checked={Trigger$[selectedIndex$]?.end_time_status}
                onChange={() => {
                  handleEndTime(
                    !Trigger$[selectedIndex$]?.end_time_status,
                    "end_time_status"
                  );
                }}
              />
              {Trigger$[selectedIndex$]?.end_time_status && (
                <div className="mt-2">
                  <DatePickerCommon
                    time="end_time"
                    oldSelectedDate={Trigger$[selectedIndex$]?.end_time}
                  />
                </div>
              )}
            </div>

          </VerticalStack>

        </HorizontalGrid>

        {/* select product modal */}
        {
          openProductPicker &&
          <ProductModal modalName={'Products'} open={openProductPicker} cancle={cancleProduct} data={productdata} loading={productLoading} />
        }


        {/* specific collections modal */}
        {
          openCollectionsPicker &&
          <ProductModal modalName={'Collection'} open={openCollectionsPicker} cancle={cancleCollection} data={collectionsdata} loading={collectionLoading} />
        }

        {
          toaststatus && (
            <ToastCommon
              status={toaststatus}
              msg={toastmsg}
              setToastStatus={setToastStatus}
              error={true}
              time={2500}
            />
          )
        }

      </div>
    </>
  );
}
