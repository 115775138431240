// import { Provider, ResourcePicker } from '@shopify/app-bridge-react';
import { Button, Checkbox, Divider, HorizontalGrid, HorizontalStack, Icon, RadioButton, TextField, VerticalStack } from '@shopify/polaris'
import { DeleteMinor, QuestionMarkInverseMinor } from '@shopify/polaris-icons';
import React, { useCallback, useState } from 'react'

export default function AddCustomField({ handleDelete }) {

    //selec text or dropdown
    const [customValue, setValue] = useState('text');
    const handleChange = useCallback(
        (_, newValue) => setValue(newValue), [],
    );

    //set label
    const [textLabel, setLabel] = useState('Jaded Pixel');
    const handleLabelChange = useCallback(
        (value) => setLabel(value),
        [],
    );

    //set First name
    const [fName, setFirstNAme] = useState('Jaded Pixel');
    const handleFirstname = useCallback(
        (value) => setFirstNAme(value),
        [],
    );

    //require
    const [require, setRequire] = useState(false);
    const handleRequired = useCallback(
        (value) => setRequire(value),
        [],
    );

    //charLimit
    const [charLimit, setcharLimit] = useState(false);
    const handleCharLimit = useCallback(
        (value) => setcharLimit(value),
        [],
    ); //limit number of chrcator
    const [selectLimit, setSelectLimit] = useState('1');
    const handleLimit = useCallback(
        (value) => setSelectLimit(value),
        [],
    );

    //Add placeholder
    const [addPlaceholder, setplaceholder] = useState(false);
    const handleAddPlaceholder = useCallback(
        (value) => setplaceholder(value),
        [],
    ); //valur placeholder
    const [placeholderVal, setplaceholderVal] = useState('Placeholder');
    const handleplaceVal = useCallback(
        (value) => setplaceholderVal(value),
        [],
    );

    //Add Deafult
    const [addVal, setDefault] = useState(false);
    const handleAddVal = useCallback(
        (value) => setDefault(value),
        [],
    ); //set Deafult value
    const [defaultVal, setDefaultVal] = useState('Default value');
    const handleDefaultVal = useCallback(
        (value) => setDefaultVal(value),
        [],
    );

    //add Variants
    const [addVariants, setVariants] = useState(false);
    const handleAddVariants = useCallback(
        (value) => setVariants(value),
        [],
    );

    //dropdown option value
    const [optionVal, setOptionVal] = useState('');
    const handleOptionVal = useCallback(
        (value) => setOptionVal(value),
        [],
    );

    //Add option
    const [addOption, setOptionlist] = useState(false);
    const AddOption = () => {
        setOptionlist(true);
    }
    const removeOption = () => {
        setOptionlist(false);
    }
  
    return (
        <div>
            <HorizontalStack gap='4' align='space-between'>
                <RadioButton
                    label="Text"
                    checked={customValue === 'text'}
                    id="text"
                    name="products"
                    onChange={handleChange}
                />
                <RadioButton
                    label="Dropdown"
                    id="dropdown"
                    name="products"
                    checked={customValue === 'dropdown'}
                    onChange={handleChange}
                />
                <Button onClick={handleDelete} plain>
                    <Icon
                        source={DeleteMinor}
                        color="base"
                    />
                </Button>
            </HorizontalStack>
            <Divider />

            <HorizontalGrid gap="2" columns={{ sm: 2 }}>
                <TextField
                    label="Label"
                    value={textLabel}
                    onChange={handleLabelChange}
                    labelAction={{
                        content:
                            <Icon
                                source={QuestionMarkInverseMinor}
                                color="base" />
                    }}
                    autoComplete="off"
                />
                <TextField
                    label="Field Name"
                    value={fName}
                    onChange={handleFirstname}
                    labelAction={{
                        content:
                            <Icon
                                source={QuestionMarkInverseMinor}
                                color="base" />
                    }}
                    autoComplete="off"
                />
            </HorizontalGrid>

            <VerticalStack>
                {customValue === 'text' && (
                    <>
                        <div className='mt-2'>
                            <Checkbox
                                label='Required field'
                                checked={require}
                                onChange={handleRequired}
                            />
                        </div>
                        <div className='mt-2 mx-125'>
                            <Checkbox
                                label='Character limit'
                                checked={charLimit}
                                onChange={handleCharLimit}
                            />
                            {charLimit && (
                                <div className='mt-2'>
                                    <TextField
                                        value={selectLimit}
                                        type='number'
                                        onChange={handleLimit}
                                    />
                                </div>
                            )}
                        </div>
                        <div className='mt-2 '>
                            <Checkbox
                                label='Add placeholder text'
                                checked={addPlaceholder}
                                onChange={handleAddPlaceholder}
                            />
                            {addPlaceholder && (
                                <div className='mt-2'>
                                    <TextField
                                        value={placeholderVal}
                                        type='text'
                                        placeholder='Placeholder'
                                        onChange={handleplaceVal}
                                    />
                                </div>
                            )}
                        </div>
                        <div className='mt-2 '>
                            <Checkbox
                                label={
                                    <div className='d-flex'>Default value <span className='ps-1'>
                                        <Icon
                                            source={QuestionMarkInverseMinor}
                                            color="base" /></span>
                                    </div>
                                }
                                checked={addVal}
                                onChange={handleAddVal}
                            />
                            {addVal && (
                                <div className='mt-2'>
                                    <TextField
                                        value={defaultVal}
                                        type='text'
                                        placeholder='Default value'
                                        onChange={handleDefaultVal}
                                    />
                                </div>
                            )}
                        </div>
                    </>
                )}

                <div className='mt-2'>
                    <Checkbox
                        label={
                            <div className='d-flex'>Show on specific variants <span className='ps-1'>
                                <Icon
                                    source={QuestionMarkInverseMinor}
                                    color="base" /></span>
                            </div>
                        }
                        checked={addVariants}
                        onChange={handleAddVariants}
                    />
                    {addVariants && (
                        <div className='mt-2'>
                            select a specific variants
                            {/* <Button onClick={() => setOpen(true)}>Click here</Button> */}
                            {/* <Provider>
                            <AppProvider>
                            <ResourcePicker
                                resourceType="Product" // Replace with the resource type you want to pick
                                open={open}
                                onSelection={(resources) => handleSelection(resources)}
                                onCancel={() => setOpen(false)}
                            />
                            </AppProvider>
                            </Provider> */}
                        </div>
                    )}

                </div>

                {customValue === 'dropdown' && (
                    <>
                        {addOption && (
                            <div className="options">
                                <div className='mt-2'>
                                    <TextField
                                        value={optionVal}
                                        type='text'
                                        label="Option #1"
                                        suffix={

                                            <span className='cursor-pointer' onClick={removeOption}>
                                                <Icon

                                                    source={DeleteMinor}
                                                    color="base"
                                                />
                                            </span>
                                        }
                                        onChange={handleOptionVal}
                                    />
                                </div>
                            </div>
                        )}
                        <div className='mt-2 '>
                            <Button onClick={AddOption}>Add an Option</Button>
                        </div>
                    </>
                )}

            </VerticalStack>
        </div>
    )
}
