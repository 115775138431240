/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { Checkbox, HorizontalGrid, Icon, Tag, Text, TextField, Tooltip, VerticalStack } from '@shopify/polaris'
import { QuestionMarkInverseMinor } from "@shopify/polaris-icons";
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { deleteupdateOfferData, updateOfferData } from '../../../../../../Redux/features/NewFunnel/newfunnelSlice';
import ProductModal from '../../../Product_Modal/ProductModal';

const UpsellProductAdvanced = ({ item, index, funnelData }) => {
    const dispatch = useDispatch();
    let queue = [];
    const [openVariantsPicker, setOpenVariantsPicker] = useState(false);
    const [variants, setVariants] = useState([])

    const setupHandleChange = useCallback((value, name) => {
        const updatedFields = {
            [name]: value,
        };
        dispatch(updateOfferData({ offer_number: item?.offer_number, offer_name: item?.offer_name, updatedFields }));
    }, [funnelData]);

    const handleOfferTextChange = useCallback((value, name) => {
        const updatedFields = {
            [name]: value.trimStart(),
        };
        dispatch(updateOfferData({ offer_number: item?.offer_number, offer_name: item?.offer_name, updatedFields }));
    }, [funnelData]);

    const cancleVariant = useCallback(
        () => setOpenVariantsPicker(!openVariantsPicker),
        [openVariantsPicker]);

    useEffect(() => {
        // variants...
        if (item?.upsell_product?.length > 0) {
            item?.upsell_product?.map((item) => {
                queue.push(...item?.variants)
                setVariants(queue);
            })
        } else {
            setVariants([]);
        }
    }, [item?.upsell_product]);

    const listSelectedvarinatTags =
        (item.exclude_product_variants_list?.length > 0) && (item?.upsell_product?.length > 0) ? (
            <div>
                {item.exclude_product_variants_list?.map(
                    (product) => (
                        <Tag
                            onRemove={() =>
                                dispatch(deleteupdateOfferData({ offer_number: item?.offer_number, offer_name: item?.offer_name, updatedFields: product.id, name: "Offer_Variants" }))
                            }
                            key={product.id}
                        >
                            <div>
                                <span>{product.title}</span>
                            </div>
                        </Tag>
                    )
                )}
            </div>
        ) : null;
        
    return (
        <>
            <HorizontalGrid gap="4" columns={{ md: 2 }}>
                <VerticalStack>
                    <Text variant="headingMd" as="h5">
                        <div className="d-flex align-center">
                            Advanced
                            <span className="ps-1">
                                <Tooltip content="Advanced">
                                    <Icon source={QuestionMarkInverseMinor} color="base" />
                                </Tooltip>
                            </span>
                        </div>
                    </Text>
                    <p>Select a product that will be offered to your buyer.</p>
                </VerticalStack>

                <VerticalStack>

                    <div className="mt-2">
                        <Checkbox
                            label={
                                <>
                                    <div className="d-flex">
                                        Add offer description{" "}
                                        <span className="ps-1">
                                            <Tooltip content="Add a short and sweet offer description. This would show below the product variants and images.">
                                                <Icon source={QuestionMarkInverseMinor} color="base" />
                                            </Tooltip>
                                        </span>
                                    </div>
                                </>
                            }
                            checked={item?.show_offer_description}
                            onChange={(value) =>
                                setupHandleChange(value, "show_offer_description")
                            }
                        />
                        {item?.show_offer_description && (
                            <div className="mt-2">
                                <TextField
                                    placeholder='For example: Keep the eco-friendly vibe going. This product is made from 100% organic recyclable materials. Get the total green look now.'
                                    type="text"
                                    value={item?.description}
                                    multiline={3}
                                    autoComplete="off"
                                    onChange={(value) =>
                                        handleOfferTextChange(value, "description")
                                    }
                                />
                            </div>)
                        }



                    </div>

                    <div className="mt-2">
                        <Checkbox
                            label={
                                <>
                                    <div className="d-flex">
                                        Include free shipping for clients who accept this offer{" "}
                                        <span className="ps-1">
                                            <Tooltip content="Cart / product page funnels - apply free shipping on the entire order. Thank you page funnels - apply free shipping on all upsell products.">
                                                <Icon source={QuestionMarkInverseMinor} color="base" />
                                            </Tooltip>
                                        </span>
                                    </div>
                                </>
                            }
                            checked={item?.include_free_shipping}
                            onChange={(value) =>
                                setupHandleChange(value, "include_free_shipping")
                            }
                        />
                    </div>

                    <div className="mt-2">
                        <Checkbox
                            label={
                                <>
                                    <div className="d-flex">
                                        Exclude variants{" "}
                                        <span className="ps-1">
                                            <Tooltip content="Check this box if you want to exclude some variants from this offer.">
                                                <Icon source={QuestionMarkInverseMinor} color="base" />
                                            </Tooltip>
                                        </span>
                                    </div>
                                </>
                            }
                            checked={item?.exclude_variants && item?.upsell_product?.length > 0}
                            onChange={(value) =>
                                setupHandleChange(value, "exclude_variants")
                            }
                            disabled={item?.upsell_product?.length === 0}
                        />
                        {item?.exclude_variants && item?.upsell_product?.length > 0 && (
                            <div className="mt-2 product_select">

                                {/* Select variants */}
                                <div
                                    onClick={() => {
                                        setOpenVariantsPicker(true);
                                    }}>
                                    <TextField
                                        value=""
                                        placeholder="Select variants"
                                        verticalContent={listSelectedvarinatTags}
                                        labelAction={{
                                            content: (
                                                <Icon
                                                    source={QuestionMarkInverseMinor}
                                                    color="base"
                                                />
                                            ),
                                        }}
                                        autoComplete="off"
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </VerticalStack>
            </HorizontalGrid>

            {/* Select variants resource picker */}
            
            {
                openVariantsPicker &&
                <ProductModal modalName={'Variants'} open={openVariantsPicker} cancle={cancleVariant} data={variants} item={item} type={"Offer_Variants"} />
            }
        </>
    )
}

export default UpsellProductAdvanced
