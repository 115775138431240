import { Button, Popover, useBreakpoints, HorizontalGrid, Box, VerticalStack, HorizontalStack, DatePicker } from '@shopify/polaris';
import { CalendarMinor } from '@shopify/polaris-icons';
import React, { useRef, useState } from 'react';

const DatePickerCustom = ({ setDateObj, setRevenueDateObj, type }) => {
  const { mdDown, lgUp } = useBreakpoints();
  const shouldShowMultiMonth = lgUp;
  const today = new Date(new Date().setHours(0, 0, 0, 0));
  const twoMonthsAgo = new Date(new Date().setMonth(today.getMonth() - 2));
  const [popoverActive, setPopoverActive] = useState(false);
  const [activeDateRange, setActiveDateRange] = useState({
    period: {
      start_date: twoMonthsAgo,
      end_date: today,
    },
  });

  const [{ month, year }, setDate] = useState({
    month: activeDateRange.period.start_date.getMonth(),
    year: activeDateRange.period.start_date.getFullYear(),
  });
  const datePickerRef = useRef(null);

  function handleMonthChange(month, year) {
    setDate({ month, year });
  }

  function handleCalendarChange({ start, end }) {
    setActiveDateRange({
      period: {
        start_date: start,
        end_date: end,
      },
    });
  }

  function apply() {
    if (type === "RevenueGrowthData") {
      setRevenueDateObj(activeDateRange.period)
    } else {

      setDateObj(activeDateRange.period)
    }
    setPopoverActive(false);
  }

  function cancel() {
    setPopoverActive(false);
  }

  return (
    <Popover
      active={popoverActive}
      autofocusTarget="none"
      preferredAlignment="left"
      preferredPosition="below"
      fluidContent
      sectioned={false}
      fullHeight
      activator={
        <Button
          size="slim"
          icon={CalendarMinor}
          onClick={() => setPopoverActive(!popoverActive)}
        >
          {activeDateRange.period.start_date.toDateString()} - {activeDateRange.period.end_date.toDateString()}
        </Button>
      }
      onClose={() => setPopoverActive(false)}
    >
      <Popover.Pane fixed>
        <HorizontalGrid
          columns={{
            xs: "1fr",
            mdDown: "1fr",
            md: "max-content max-content",
          }}
          gap={0}
          ref={datePickerRef}
        >
          <Box padding={{ xs: 500 }} maxWidth={mdDown ? "320px" : "516px"}>

            <VerticalStack gap="400">
              <div>
                <DatePicker
                  month={month}
                  year={year}
                  selected={{
                    start: activeDateRange.period.start_date,
                    end: activeDateRange.period.end_date,
                  }}
                  onMonthChange={handleMonthChange}
                  onChange={handleCalendarChange}
                  multiMonth={shouldShowMultiMonth}
                  allowRange
                />
              </div>
            </VerticalStack>
          </Box>
        </HorizontalGrid>
      </Popover.Pane>
      <Popover.Pane fixed>
        <Popover.Section>
          <HorizontalStack align="end" gap="10">
            <Button onClick={cancel}>Cancel</Button>
            <Button primary onClick={apply}>
              Apply
            </Button>
          </HorizontalStack>
        </Popover.Section>
      </Popover.Pane>
    </Popover>
  );
};

export default DatePickerCustom;
