import { configureStore } from "@reduxjs/toolkit";
import funnelSlice from "./features/Funnel/funnelSlice";
import newfunnelSlice from "./features/NewFunnel/newfunnelSlice";
import upsellTabsSlice from "./features/NewFunnel/upsellTabsSlice";

export const store = configureStore({
  reducer: {
    funnel: funnelSlice,
    newfunnel: newfunnelSlice,
    upsellTabs: upsellTabsSlice,
  },
});
