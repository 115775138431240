/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Divider, HorizontalStack, Icon, Tabs, Text } from '@shopify/polaris'
import { DeleteMajor, } from "@shopify/polaris-icons";
import { CirclePlusMinor } from "@shopify/polaris-icons";
import iconjoin from "../../../../Assets/images/icons/joinline.svg";
import React, { useCallback, useState } from 'react'
import VariantTriggers from './partials/VariantTriggers';
import VarintUpsell from './partials/VariantUpsell';
import UpsellOfferText from './partials/UpsellOfferText';
import UpsellAdvanced from './partials/UpsellAdvanced';
import Trigger from '../Step_Two/Trigger';
import { useDispatch, useSelector } from 'react-redux';
import { addUpsellOfferData, defaultUpsellData, deleteupdateFunnel, setOfferSource, setSelectedTab } from '../../../../Redux/features/NewFunnel/newfunnelSlice';
import { useEffect } from 'react';
import UpsellProduct from './partials/Upsell_Product/UpsellProduct';
import UpsellProductOfferText from './partials/Upsell_Product/UpsellProductOfferText';
import UpsellProductAdvanced from './partials/Upsell_Product/UpsellProductAdvanced';
import { setProductUpsell, setVariantUpsell } from '../../../../Redux/features/NewFunnel/upsellTabsSlice';

const SetupUpsellOfferMain = () => {
    const selectedIndex$ = useSelector((state) => state?.newfunnel?.seleted_step);
    const selectedTab = useSelector((state) => state?.newfunnel?.selectedTab);
    const newFunnel$ = useSelector((state) => state?.newfunnel?.data[selectedIndex$]);
    const triggerDataName = useSelector(state => state?.newfunnel?.data[1]?.upsell_product_detail?.title)

    const upsellTabData = useSelector((state) => state?.upsellTabs);
    const funnelData = newFunnel$?.offer;
    const [uniqueData, setUniqueData] = useState([]);
    const lastIndex = funnelData?.length;

    const dispatch = useDispatch();

    useEffect(() => {

        if (selectedTab === 0) {
            const newData = {
                // offer_name: null,
                offer_number: 1,
                variant_trigger: [],
                variant_upsell: [],
                offer_text: "",
                show_different_language: false,
                language: [],
                show_offer_description: false,
                description: "",
                include_free_shipping: false,
            }
            if (upsellTabData?.variant_upsell?.some((value) => (value?.variant_trigger?.length > 0 || value?.variant_upsell?.length > 0 || value?.offer_text?.length > 0 || value?.language?.length > 0 || value?.description?.length > 0 || value?.show_different_language || value?.show_offer_description || value?.include_free_shipping))) {
                dispatch(defaultUpsellData(upsellTabData?.variant_upsell))
            } else {
                dispatch(defaultUpsellData([newData]))
            }
            dispatch(setOfferSource("upsell_variant"))
        }
        if (selectedTab === 1) {
            const newData = {
                // offer_name: null,
                offer_number: 1,
                upsell_product: [],
                offer_text: "",
                language: [],
                show_different_language: false,
                show_offer_description: false,
                description: "", 
                include_free_shipping: false,
                exclude_variants: false,
                exclude_product_variants_list: [],
            }
            if (upsellTabData?.product_upsell.some((value) => value?.upsell_product?.length > 0 || value?.offer_text?.length > 0 || value?.language?.length > 0 || value?.description?.length > 0 || value?.exclude_product_variants_list?.length > 0 || value?.show_different_language || value?.show_offer_description || value?.include_free_shipping || value?.exclude_variants)) {
                dispatch(defaultUpsellData(upsellTabData?.product_upsell))
            } else {
                dispatch(defaultUpsellData([newData]))
            }
            dispatch(setOfferSource("upsell_product"))
        }
    }, [selectedTab])
    useEffect(() => {
        if (selectedTab === 0) {
            dispatch(setVariantUpsell(funnelData))
        }
        if (selectedTab === 1) {
            dispatch(setProductUpsell(funnelData))
        }

    }, [funnelData])

    const handleTabChange = useCallback(
        (selectedTabIndex) => dispatch(setSelectedTab(selectedTabIndex)),
        [],
    );

    const tabs = [
        {
            id: 'variantTab',
            content: 'Variant Upsell',
        },
        {
            id: 'productTab',
            content: 'Product Upsell',
        },
    ];

    const addDataHandler = () => {
        funnelData.map((item) => {
            const setupOfffersData = {
                // offer_name: null,
                offer_number: item?.offer_number + 1,
                variant_trigger: [],
                variant_upsell: [],
                offer_text: "",
                show_different_language: false,
                language: [],
                show_offer_description: false,
                description: "",
                include_free_shipping: false,

            };

            dispatch(addUpsellOfferData({ updatedFields: [...funnelData, setupOfffersData] }))
            return null;
        })
    }

    const deleteoffer = (offer_number, offer_name) => {
        dispatch(deleteupdateFunnel({ offer_number: offer_number, offer_name: offer_name }));
    };

    return (
        <>
            {/* {selectedTab === 1 && <Trigger />} */}
            <Trigger />
            <div className='upsell_tab_wrapper'>
                <Tabs tabs={tabs} selected={selectedTab} onSelect={handleTabChange}></Tabs>
            </div>

            {
                selectedTab === 0 &&
                <>
                    {funnelData?.length > 0 &&
                        funnelData?.map((item, index) => {
                            return (
                                <div className='add_downsell_main_wrap' key={index}>
                                    <div className="box_wrap pb-5">
                                        <HorizontalStack align="space-between">
                                            <Text variant="headingMd" as="h5" fontWeight="semibold">
                                                Offer #{item?.offer_number}
                                            </Text>
                                            {
                                                index !== 0 &&
                                                <>
                                                    <Button plain onClick={() => deleteoffer(item?.offer_number, item?.offer_name)} >
                                                        <Icon source={DeleteMajor} color="base" />
                                                    </Button>
                                                </>
                                            }
                                        </HorizontalStack>

                                        <Divider />

                                        <VariantTriggers item={item} index={index} funnelData={funnelData} setUniqueData={setUniqueData} />

                                        <Divider />

                                        <VarintUpsell item={item} index={index} funnelData={funnelData} />

                                        <Divider />

                                        <UpsellOfferText item={item} index={index} funnelData={funnelData} />

                                        <Divider />

                                        <UpsellAdvanced item={item} index={index} funnelData={funnelData} />
                                    </div>
                                    {
                                        index !== lastIndex - 1 &&
                                        <div>
                                            <img
                                                src={iconjoin}
                                                alt="icon"
                                                className="d-block mx-auto"
                                            />
                                        </div>
                                    }

                                </div>
                            );
                        })
                    }

                    {
                        uniqueData?.length > 0 &&
                        <>
                            <div>
                                <img
                                    src={iconjoin}
                                    alt="icon"
                                    className="d-block mx-auto"
                                />
                            </div>
                            <div className="add_another">
                                <Button primary
                                    onClick={() => { addDataHandler() }}
                                >
                                    <HorizontalStack blockAlign="center" gap="2">
                                        <Icon source={CirclePlusMinor} color="base" /> Add another offer
                                    </HorizontalStack>
                                </Button>
                            </div>
                        </>
                    }
                </>
            }

            {
                selectedTab === 1 &&
                <>
                    {funnelData?.length > 0 &&
                        <div className='add_downsell_main_wrap'>
                            <div className="box_wrap pb-5">
                                <HorizontalStack align="space-between">
                                    <Text variant="headingMd" as="h5" fontWeight="semibold">
                                        Offer #1
                                    </Text>
                                </HorizontalStack>

                                <Divider />

                                <UpsellProduct item={funnelData[0]} funnelData={funnelData} triggerDataName={triggerDataName} />

                                <Divider />

                                <UpsellProductOfferText item={funnelData[0]} funnelData={funnelData} />

                                <Divider />

                                <UpsellProductAdvanced item={funnelData[0]} funnelData={funnelData} />
                            </div>
                        </div>

                    }
                </>
            }


        </>

    )
}

export default SetupUpsellOfferMain
